import { Link } from 'react-router-dom';
import logo from '../imgs/logo.png'
const Navbar = () => {
    return (
        <div className="Navbar">
            <div className='logo w-fit m-auto md:m-0'>
                <Link to={'/'}>
                    <img src={logo} alt="logo" className='w-[200px] h-[150px]' />
                </Link>
            </div>
        </div>
    );
}

export default Navbar;