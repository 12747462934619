import axios from "axios";
import { useState } from "react";

const AddAdmin = () => {
    const [adminEmail,setAdminEmail] = useState('')
    const [adminPassword, setAdminPassword] = useState('')
    const [adminPlace, setAdminPlace] = useState('')
    const [adminRole, setAdminRole] = useState('')
    const handleSubmit = () => {
        axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/add`,{
            adminEmail,adminPassword,adminPlace,adminRole
        }).then((res) => {
            alert('added')
        }).catch((err) => {
            alert('err')
        })
    }
    return (
        <div className="space-x-5">
            <input type="text" onChange={(e)=>{
                setAdminEmail(e.target.value)
            }} />
            <input type="text" onChange={(e)=>{
                setAdminPassword(e.target.value)
            }} />
            <input type="text" onChange={(e)=>{
                setAdminPlace(e.target.value)
            }} />
            <input type="role" onChange={(e)=>{
                setAdminRole(e.target.value)
            }} />
            <button onClick={handleSubmit}>submit</button>
        </div>
    );
}

export default AddAdmin;