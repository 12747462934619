import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddContract = () => {
    const [contractPlace,setContractPlace] = useState('')
    const [contract,setContract] = useState('')
    const [contractPrice,setContractPrice] = useState('')
    const [contractInstallment,setContractInstallment] = useState('')
    const [contractPieces, setContractPieces] = useState('')
    const [contractPiecePrice, setContractPiecePrice] = useState('')
    const [restContractPrice, setRestContractPrice] = useState('')
    const [details, setDetails] = useState('')
    const [places, setPlaces] = useState('')
    const navigate = useNavigate('');
    const [loading, setLoading] = useState('')

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload()
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };


    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (!contract || !contractPrice || !contractPiecePrice || !contractPieces) {
                return showRequiredAlert("تأكد من ملئ البيانات الاجبارية (جميع البيانات معادا التفاصبل)")
            }
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}contract/contracts`, {
                contractPlace,
                contract,
                contractPrice,
                contractInstallment,
                contractPiecePrice,
                contractPieces,
                restContractPrice,
                details
            });

            const res2 = await axios.post(`${process.env.REACT_APP_HOST_SERVER}servedcontract/contracts`, {
                contractPlace,
                contract,
                contractPrice,
                contractInstallment,
                contractPiecePrice,
                contractPieces,
                restContractPrice,
                details
            });
            showSuccessAlert("تمت الاضافة بنجاح")
            setLoading(false)
            setContractPlace('')
            setContract('')
            setContractInstallment('')
            setContractPrice('')
            setContractPieces('')
            setContractPiecePrice('')
            setRestContractPrice('')
            setDetails('')
        } catch (err) {
            console.log("errerrrrr", err)
            showErrorAlert("حدث خطأ اثناء الاضافة")
        } finally {
            setLoading(false)
        }
    };

    const getPlaces = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        verifyAdmin();
        getPlaces();
    }, []);

    return (
        <div>
            <div className="px-4">
                <Navbar />
            </div>
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">المكان او صاحب العقد</label>
                    <select
                        onChange={(e) => setContractPlace(e.target.value)}
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                    >
                        <option value="" selected>من فضلك اختر المكان</option>
                        {
                            places && places.map((item) => (
                                <option value={item._id}>{item.place}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">غرض العقد او صاحب العقد</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={contract }
                        onChange={(e) => setContract(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">قيمة العقد</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={contractPrice}
                        onChange={(e) => setContractPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">القسط المدفوع من العقد (ان وجد)</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={contractInstallment}
                        onChange={(e) => setContractInstallment(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">عدد القطع</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={contractPieces}
                        onChange={(e) => setContractPieces(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">قيمة القطعة</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={contractPiecePrice}
                        onChange={(e) => setContractPiecePrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">المبلغ الباقي من العقد</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={restContractPrice}
                        onChange={(e) => setRestContractPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">التفاصيل (ان وجد)</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={details}
                        disabled={loading}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                    >
                        أضافة العقد
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddContract;
