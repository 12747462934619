import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const BlessingHistory = () => {
    const [blessings, setBlessings] = useState([]);
    const [filteredBlessings, setFilteredBlessings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [nameFilter, setNameFilter] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");

    const fetchBlessings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}blessing/`);
            setBlessings(response.data.data);
            setFilteredBlessings(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const downloadblessingCSV = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}blessing/csv`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'blessings.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading CSV file:", error);
        }
    }

    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        fetchBlessings();
    }, []);

    const uniqueDepartments = [...new Set(blessings.map((blessing) => blessing.departement))];


    const handleSearch = () => {
        let filtered = blessings;

        if (nameFilter) {
            filtered = filtered.filter(blessing =>
                blessing.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (departmentFilter) {
            filtered = filtered.filter(blessing =>
                blessing.departement.toLowerCase().includes(departmentFilter.toLowerCase())
            );
        }

        if (startDateFilter && endDateFilter) {
            filtered = filtered.filter(blessing => {
                const servedDate = new Date(blessing.servedDate);
                return (
                    servedDate >= new Date(startDateFilter) && servedDate <= new Date(endDateFilter)
                );
            });
        }

        setFilteredBlessings(filtered);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedData = filteredBlessings.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredBlessings.length / itemsPerPage);

    const totalBasic = filteredBlessings.reduce((acc, curr) => acc + curr.basic, 0);

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}blessing/${id}`);
                    fetchBlessings();
                    Swal.fire('تم الحذف!', 'تم حذف البركة.', 'success');
                } catch (error) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء حذف البركة.', 'error');
                }
            }
        });
    };


    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center">عرض البركات</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <input
                    type="text"
                    placeholder="البحث بالاسم"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <select
                    value={departmentFilter}
                    onChange={(e) => setDepartmentFilter(e.target.value)}
                    className="border-gray-300 rounded-md shadow-sm py-2 px-4"
                >
                    <option selected value="">الادارات</option>
                    {uniqueDepartments.map((department) => (
                        <option key={department} value={department}>
                            {department}
                        </option>
                    ))}
                </select>
                <input
                    type="date"
                    placeholder="تاريخ البداية"
                    value={startDateFilter}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <input
                    type="date"
                    placeholder="تاريخ النهاية"
                    value={endDateFilter}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
            </div>

            <button
                onClick={handleSearch}
                className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
            >
                بحث
            </button>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-lg rounded-lg">
                    <thead className="bg-indigo-700 text-white">
                        <tr>
                            <th className="py-4 px-6 text-right">الاسم</th>
                            <th className="py-4 px-6 text-right">الادارة</th>
                            <th className="py-4 px-6 text-right">الوظيفة</th>
                            <th className="py-4 px-6 text-right">المرتب الأساسي</th>
                            <th className="py-4 px-6 text-right">تاريخ التعيين</th>
                            <th className="py-4 px-6 text-right">تاريخ الصرف</th>
                            <th className="py-4 px-6 text-right">المناسبة</th>
                            <th className="py-4 px-6 text-center">اجرائات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((blessing, index) => (
                                <tr key={index} className="border-b even:bg-gray-100">
                                    <td className="py-4 px-6 text-right">{blessing.name}</td>
                                    <td className="py-4 px-6 text-right">{blessing.departement}</td>
                                    <td className="py-4 px-6 text-right">{blessing.jobTitle}</td>
                                    <td className="py-4 px-6 text-right">{blessing.basic.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td className="py-4 px-6 text-right">{new Date(blessing.startDate).toLocaleDateString("en-uk")}</td>
                                    <td className="py-4 px-6 text-right">{new Date(blessing.servedDate).toLocaleDateString("en-uk")}</td>
                                    <td className="py-4 px-6 text-right">{blessing.occasion ? blessing.occasion.occasion : "N/A"}</td>
                                    <td className="py-4 px-6 text-center">
                                        <button
                                            onClick={() => handleDelete(blessing._id)}
                                            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="py-4 text-center">لم يتم العثور على سجلات</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="mt-6 text-lg font-bold text-center">
                المجموع الإجمالي للمرتب الأساسي: {totalBasic.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} جنيه
            </div>

            <div className="print:hidden">
                <button
                    onClick={downloadblessingCSV}
                    className="px-2 bg-lime-500 rounded-lg py-2 text-white mt-3"
                >تحميل ملف البركات المصروفة</button>
            </div>

            <div className="mt-6 flex justify-center items-center space-x-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 mx-4 rounded-lg ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    السابق
                </button>
                <div className="flex items-center space-x-2">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={currentPage}
                        onChange={(e) => handlePageChange(Number(e.target.value))}
                        className="w-14 text-center border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <span className="text-sm font-semibold text-gray-700">/ {totalPages}</span>
                </div>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    التالي
                </button>
            </div>

        </div>
    );
};

export default BlessingHistory;
