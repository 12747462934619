import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const AddWorkerSalary = () => {
    const [formData, setFormData] = useState({
        salaryMonth: '',
        departement:'',
        treasury: '',
        name: '',
        jobTitle: '',
        basic: '',
        allowances: '',
        bagAllowance: '',
        incentives: '',
        overtime: '',
        advance: '',
        advanceDeduction: '',
        penaltyDeduction: '',
        insuranceDeduction: '',
        otherDeductions: ''
    });
    const [loading, setLoading] = useState('')
    const [data, setData] = useState('')
    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers`).then((res) => {
            setData(res.data.workers)
        }).catch((err) => { 
            console.log(err)
        })
    }
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate()

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "name") {
            const selectedWorker = data.find(worker => worker.name === value);
            setFormData({
                ...formData,
                [name]: value,
                departement: selectedWorker?.departement || '',
                jobTitle: selectedWorker?.job || ''
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setSuccessMessage('');
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}salary/add`, formData);
            setSuccessMessage('تم إضافة السجل بنجاح');
            setFormData({
                salaryMonth: '',
                departement: '',
                treasury: '',
                name: '',
                jobTitle: '',
                basic: '',
                allowances: '',
                bagAllowance: '',
                incentives: '',
                overtime: '',
                advance: '',
                advanceDeduction: '',
                penaltyDeduction: '',
                insuranceDeduction: '',
                otherDeductions: ''
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setErrors(error.response.data.message)
            if (error.response && error.response.data.errors) {
                setErrors(error.response.data.errors);
            }
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        verifyAdmin()
        getData()
    },[])
    return (
        <div>
            <Navbar/>
        <div className="container mx-auto">
            <h1 className="text-3xl font-bold mb-8 text-center">إدخال بيانات المرتب</h1>

            {successMessage && (
                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
                    <p>{successMessage}</p>
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-lg rounded-lg p-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Name */}
                        <div>
                            <label className="block text-lg font-medium text-gray-700">الاسم</label>
                            <select
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={`mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md ${errors.name ? 'border-red-500' : ''}`}
                            >
                                <option value="" className='text-lg' disabled>اختر اسم</option>
                                {
                                    data && data.map((item) => (
                                        <option key={item.id} value={item.name} className='text-lg'> {item.name} </option>
                                    ))
                                }
                            </select>
                            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                        </div>

                        {/* Departemnt */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">الادارة</label>
                        <input
                            type="text"
                            name="departement"
                            value={formData.departement}
                            onChange={handleChange}
                            disabled
                            className={`mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md ${errors.departement ? 'border-red-500' : ''}`}
                        />
                        {errors.departement && <p className="text-red-500 text-xs mt-1">{errors.departement}</p>}
                    </div>
                        


                    {/* Job Title */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">الوظيفة</label>
                        <input
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            disabled
                            className={`mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md ${errors.jobTitle ? 'border-red-500' : ''}`}
                        />
                        {errors.jobTitle && <p className="text-red-500 text-xs mt-1">{errors.jobTitle}</p>}
                    </div>

                    {/* Basic Salary */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">المرتب الأساسي</label>
                        <input
                            type="number"
                            name="basic"
                            value={formData.basic}
                            onChange={handleChange}
                            className={`mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md ${errors.basic ? 'border-red-500' : ''}`}
                        />
                        {errors.basic && <p className="text-red-500 text-xs mt-1">{errors.basic}</p>}
                    </div>

                    {/* Allowances */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">البدلات</label>
                        <input
                            type="number"
                            name="allowances"
                            value={formData.allowances}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Bag Allowance */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">بدل شنظة</label>
                        <input
                            type="number"
                            name="bagAllowance"
                            value={formData.bagAllowance}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Incentives */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">حوافز</label>
                        <input
                            type="number"
                            name="incentives"
                            value={formData.incentives}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Overtime */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">اضافي</label>
                        <input
                            type="number"
                            name="overtime"
                            value={formData.overtime}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Advance */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">سلف</label>
                        <input
                            type="number"
                            name="advance"
                            value={formData.advance}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Advance Deduction */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">خصم السلف</label>
                        <input
                            type="number"
                            name="advanceDeduction"
                            value={formData.advanceDeduction}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Penalty Deduction */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">خصم الغرامات</label>
                        <input
                            type="number"
                            name="penaltyDeduction"
                            value={formData.penaltyDeduction}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Insurance Deduction */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">خصم التأمين</label>
                        <input
                            type="number"
                            name="insuranceDeduction"
                            value={formData.insuranceDeduction}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Other Deductions */}
                    <div>
                        <label className="block text-lg font-medium text-gray-700">خصومات أخرى</label>
                        <input
                            type="number"
                            name="otherDeductions"
                            value={formData.otherDeductions}
                            onChange={handleChange}
                            className="mt-1 block w-full shadow-sm sm:text-sm bg-gray-100 py-2 border-gray-300 rounded-md"
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-lime-500 hover:bg-lime-600 text-white font-bold py-2 px-4 rounded-lg"
                >
                    حفظ
                </button>

                {Object.keys(errors).length > 0 && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-4">
                        <ul>
                            {Object.keys(errors).map((field) => (
                                <li key={field}>{errors[field]}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </form>
            </div>
        </div>
    );
};

export default AddWorkerSalary;
