import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminsFinance = () => {
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const downloadAllCSVs = async () => {
        const files = [
            { endpoint: 'blessing/csv', filename: 'blessings.csv' },
            { endpoint: 'servedcontract/csv', filename: 'contracts.csv' },
            { endpoint: 'locker/incomes/csv', filename: 'income.csv' },
            { endpoint: 'locker/expenses/csv', filename: 'expenses.csv' },
            { endpoint: 'get/products/csv', filename: 'products.csv' },
        ];
    
        try {
            await Promise.all(
                files.map(async (file) => {
                    const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}${file.endpoint}`, {
                        responseType: 'blob',
                    });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
            );
        } catch (error) {
            console.error('Error downloading CSV files:', error);
        }
    };
    

    useEffect(() => {
        verifyAdmin()
    }, [])
    return (
        <div>
            <Navbar />
            <h1 className='text-center text-3xl'>اهلا مسئول المالية</h1>
        <div className="min-h-[60vh] flex items-center justify-center">
            <div className="flex flex-wrap justify-center gap-4">
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">تنزيل النسخ الاحطياطية</h2>
                        <button onClick={downloadAllCSVs} className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">تنزيل</button>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">حركة الخزنة</h2>
                        <Link to={'/finance'}>
                            <button  className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">حركة الخزنة (المحذوفات)</h2>
                        <Link to={'/finance/deleted'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">صفحات المصروفات</h2>
                        <Link to={'/admin/expenses'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">صفحات الايرادات</h2>
                        <Link to={'/admin/incomes'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">حركة المبيعات</h2>
                        <Link to={'/admin/sales'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">الحضانة</h2>
                        <Link to={'/finance/accept'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">المنتجات</h2>
                        <Link to={'/finance/accept/products'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">صفحات الاضافات و البحوثات</h2>
                        <Link to={'/admin/add/all'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>                        
                        </Link>
                </div>
            </div>
        </div>
    </div>
    );
};

export default AdminsFinance;
