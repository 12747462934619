import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar";

const AddNurseryExpense = () => {
    const { place } = useParams()
    const [type, setType] = useState('')
    const [details, setDetails] = useState('')
    const [price, setPrice] = useState('')
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate('')
    const [loading, setLoading] = useState('')

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        })
    };


    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (!place || !type || !price) {
                return showRequiredAlert("برجاء التأكد من ملئ البيانات الاساسية");
            }

            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`, {
                place: "66fafc98cef65b167ba331ca",
                type,
                price,
                details,
                accepted:"no"
            });
            setSuccess(response.data.message);
            setError(null);
            setType('');
            setPrice('');
            setDetails('');
            showSuccessAlert("تمت الاضافة بنجاح");
        } catch (err) {
            console.log(err);
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])

    return (
        <div>
            <Navbar />
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4">
                    <label className="text-xl">نوع المصروفات</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">الثمن</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">تفاصيل</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        أضافة المصروفات
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AddNurseryExpense;
