import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AdminAddPage from "./pages/financial/AdminAddPage";
import AddPlace from "./pages/financial/AddPlace";
import AddCategory from "./pages/financial/AddCategory";
import AddProduct from "./pages/financial/AddProduct";
import AddWorker from "./pages/financial/AddWorker";
import AddFamily from "./pages/god's brothers/AddFamily";
import SearchFamily from "./pages/god's brothers/SearchFamily";
import SalesAndReturnsPage from "./pages/financial/SalesAndReturnsPage";
import AddAdmin from "./pages/addAdmin";
import AdminFinance from "./pages/financial/AdminFinance";
import AdminsFinance from "./pages/financial/AdminsFinance";
import AdminGod from "./pages/god's brothers/AdminGod";
import AddExpenses from "./pages/financial/AddExpenses";
import AddWorkerSalary from "./pages/financial/AddWorkerSalary";
import WorkerSalary from "./pages/financial/WorkerSalary";
import ServedSalary from "./pages/financial/ServedSalary";
import NotFound from "./pages/NotFound";
import SalesPage from "./pages/financial/SalesPage";
import UpdateProducts from "./pages/financial/UpdateProducts";
import UpdateCategory from "./pages/financial/UpdateCategory";
import UpdatePlace from "./pages/financial/UpdatePlace";
import AddIncomes from "./pages/financial/AddIncomes";
import AddAdvance from "./pages/financial/AddAdvance";
import AdvanceHistory from "./pages/financial/AdvanceHistory";
import ExpensePage from "./pages/financial/Expenses";
import IncomePage from "./pages/financial/Incomes";
import Workers from "./pages/financial/Workers";
import AddPenalety from "./pages/financial/AddPenalety";
import PenaletyHistory from "./pages/financial/PanaletyHistory";
import NonWorkerSalary from "./pages/financial/NonWorkerSalary";
import AddContract from "./pages/financial/Contract/AddContract";
import ContractHistroy from "./pages/financial/Contract/ContractHistory";
import AddOccasion from "./pages/financial/Blessing/AddOccasion";
import UpdateOccasion from "./pages/financial/Blessing/UpdateOccasion";
import AddBlessing from "./pages/financial/Blessing/AddBlessing";
import AddBlessingAll from "./pages/financial/Blessing/AddBlessingAll";
import BlessingHistory from "./pages/financial/Blessing/BlessingHistory";
import ServedContractHistory from "./pages/financial/Contract/ServedContractHistory";
import AddChild from "./pages/financial/Nursery/AddChild";
import ChildrenData from "./pages/financial/Nursery/Children";
import Nursury from "./pages/financial/Nursery/Nursery";
import AddList from "./pages/financial/Nursery/AddList";
import List from "./pages/financial/Nursery/List";
import AddDailyList from "./pages/financial/Nursery/AddDailyList";
import DailyList from "./pages/financial/Nursery/DailyList";
import AddVacation from "./pages/financial/Vacations/AddVacation";
import VacationHistory from "./pages/financial/Vacations/VacationHistory";
import AddNurseryIncomes from "./pages/financial/Nursery/AddNurseryIncome";
import AddNurseryExpense from "./pages/financial/Nursery/AddNurseryExpense";
import NurseryFinance from "./pages/financial/Nursery/NurseryFinance";
import AdminFinanceDeleted from "./pages/financial/AdminFinanceDeleted";
import SalesPlaces from "./Admin/SalesPlaces";
import NurseryControl from "./Admin/NurseryControl";
import ProductsControl from "./Admin/ProductsControl";
function App() {
  return (
      <div className="church-website">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/add" element={<AddAdmin/>}/>
        <Route path="login" element={<Login />} />
        <Route path="admin">
        <Route path="god" element={<AdminGod/>}/>
          {/* add pages  */}
          <Route path="add/all" element={<AdminAddPage/>}/>
          <Route path="add/place" element={<AddPlace/>}/>
          <Route path="add/category" element={<AddCategory/>}/>
          <Route path="add/product" element={<AddProduct/>}/>
          <Route path="add/worker" element={<AddWorker/>}/>
          <Route path="add/expenses" element={<AddExpenses/>}/>
          <Route path="add/incomes" element={<AddIncomes/>}/>
          <Route path="add/workersalary" element={<AddWorkerSalary/>}/>
          <Route path="add/advance" element={<AddAdvance/>}/>
          <Route path="add/penalety" element={<AddPenalety/>}/>
          <Route path="add/contract" element={<AddContract />} />
          <Route path="add/occasion" element={<AddOccasion />} />
          <Route path="add/blessing" element={<AddBlessing />} />
          <Route path="add/blessing/all" element={<AddBlessingAll />} />
          <Route path="add/vacation" element={<AddVacation />} />
          {/* history pages  */}
          <Route path="contract/history" element={<ContractHistroy />} />
          <Route path="servedcontract/history" element={<ServedContractHistory />} />
          <Route path="advance/history" element={<AdvanceHistory/>}/>
          <Route path="penalety/history" element={<PenaletyHistory/>}/>
          <Route path="blessing/history" element={<BlessingHistory/>}/>
          <Route path="vacation/history" element={<VacationHistory/>}/>
          {/* salaries */}
          <Route path="workersalary" element={<WorkerSalary />} />
          <Route path="servedsalary" element={<ServedSalary/>}/>
          <Route path="non-worker-salary" element={<NonWorkerSalary/>}/>
          <Route path="show/worker" element={<Workers/>}/>
          {/* update pages  */}
          <Route path="updateproduct" element={<UpdateProducts/>}/>
          <Route path="updatecategory" element={<UpdateCategory/>}/>
          <Route path="updateplace" element={<UpdatePlace/>}/>
          <Route path="updateoccasion" element={<UpdateOccasion/>}/>
          {/* finance  */}
          <Route path="finance" element={<AdminsFinance />} />
          <Route path="incomes" element={<IncomePage/>}/>
          <Route path="expenses" element={<ExpensePage/>}/>
          <Route path="sales" element={<SalesPlaces/>}/>
        </Route>
          <Route path="salesandreturns/:place" element={<SalesAndReturnsPage/>}/>
          <Route path="salesPage/:place" element={<SalesPage/>}/>
          <Route path="finance" element={<AdminFinance/>}/>
          <Route path="finance/deleted" element={<AdminFinanceDeleted />} />
          <Route path="finance/accept" element={<NurseryControl />} />
          <Route path="finance/accept/products" element={<ProductsControl />} />
        <Route path="god">
          <Route path="add" element={<AddFamily/>}/>
          <Route path="search" element={<SearchFamily/>}/>
        </Route>
        <Route path="nursery/:place">
          <Route path="" element={<Nursury/>}/>
          <Route path="add/child" element={<AddChild/>}/>
          <Route path="add/list" element={<AddList/>}/>
          <Route path="add/incomes" element={<AddNurseryIncomes/>}/>
          <Route path="add/expenses" element={<AddNurseryExpense/>}/>
          <Route path="add/dailylist" element={<AddDailyList/>}/>
          <Route path="nursery/finance" element={<NurseryFinance/>}/>
          <Route path="children" element={<ChildrenData/>}/>
          <Route path="lists" element={<List/>}/>
          <Route path="dailylists" element={<DailyList/>}/>
        </Route>
          <Route path="*" element={<NotFound/>}/>
      </Routes>
      </div>
  );
}

export default App;
