import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";

const AddPlace = () => {
    const [place, setPlace] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState('')
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/place`, { place });
            setSuccess(response.data.message);
            setError(null);
            setPlace('');
            setLoading(true)
        } catch (err) {
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(true)
        } finally {
            setLoading(false)
        }
    };
    
    useEffect(() => {
        verifyAdmin()
    }, [])
    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="login-heading mt-5">
                <h1 className="text-center text-3xl font-bold">صفحة اضافة الاماكن الجديدة في الكنيسة</h1>
            </div>
            <div className="form w-[50%] m-auto">
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-4">
                        <label className="text-xl">المكان الجديد</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={place}
                            onChange={(e) => setPlace(e.target.value)}
                        />
                    </div>
                    {error && (
                        <div className="mt-4 text-red-500 text-center">
                            {Array.isArray(error) ? error.map((errMsg, idx) => (
                                <p key={idx}>{errMsg}</p>
                            )) : <p>{error}</p>}
                        </div>
                    )}
                    {success && (
                        <div className="mt-4 text-green-500 text-center">
                            {success}
                        </div>
                    )}
                    <div className="submit mt-6 text-center">
                        <button type="submit" disabled={loading} className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">
                            أضافة المكان
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddPlace;
