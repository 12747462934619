import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddIncomes = () => {
    const [places, setPlaces] = useState('')
    const [place, setPlace] = useState('')
    const [type, setType] = useState('')
    const [price, setPrice] = useState('')
    const [details, setDetails] = useState('')
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState('')

    const navigate = useNavigate('')
    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/incomes`, {
                place,
                type,
                price,
                details,
                accepted:"yes"
            });
            setSuccess(response.data.message);
            setError(null);
            setType('');
            setPrice('');
            setDetails('');
            setLoading(false)
        } catch (err) {
            console.log(err)
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };


    useEffect(() => {
        verifyAdmin()
        getData()
    }, [])
    return (
        <div>
            <Navbar />
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4">
                    <label className="text-xl">المكان المستهدف</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setPlace(e.target.value)}
                    >
                        <option value={place} selected>من فضلك اختار المكان</option>
                        {
                            places && places.map((item) => (
                                <option value={item._id}>{item.place}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">نوع الايرادات</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">الثمن</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">تفاصيل</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        أضافة الايرادات
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AddIncomes;