import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AddFamily = () => {
    const [familyGod, setFamilyGod] = useState('')
    const [familyNumber, setFamilyNumber] = useState('')
    const [researchDate, setResearchDate] = useState('')
    const [godName, setGodName] = useState('')
    const [godID, setGodID] = useState('')
    const [godJob, setGodJob] = useState('')
    const [godPhone, setGodPhone] = useState('')
    const [godFatherOFConfession, setGodFatherOFConfession] = useState('')
    const [godAddreess, setGodAddreess] = useState('')
    const [children, setChildren] = useState('')
    const [childName1, setChildName1] = useState('')
    const [childID1, setChildID1] = useState('')
    const [childstatus1, setChildstatus1] = useState('')
    const [childAcademicYearOrJob1, setChildAcademicYearOrJob1] = useState('')
    const [childIncome1, setChildIncome1] = useState('')
    const [childFatherOFConfession1, setChildFatherOFConfession1] = useState('')
    const [childPhone1, setChildPhone1] = useState('')
    const [childName2, setChildName2] = useState('')
    const [childID2, setChildID2] = useState('')
    const [childstatus2, setChildstatus2] = useState('')
    const [childAcademicYearOrJob2, setChildAcademicYearOrJob2] = useState('')
    const [childIncome2, setChildIncome2] = useState('')
    const [childFatherOFConfession2, setChildFatherOFConfession2] = useState('')
    const [childPhone2, setChildPhone2] = useState('')
    const [childName3, setChildName3] = useState('')
    const [childID3, setChildID3] = useState('')
    const [childstatus3, setChildstatus3] = useState('')
    const [childAcademicYearOrJob3, setChildAcademicYearOrJob3] = useState('')
    const [childIncome3, setChildIncome3] = useState('')
    const [childFatherOFConfession3, setChildFatherOFConfession3] = useState('')
    const [childPhone3, setChildPhone3] = useState('')
    const [childName4, setChildName4] = useState('')
    const [childID4, setChildID4] = useState('')
    const [childstatus4, setChildstatus4] = useState('')
    const [childAcademicYearOrJob4, setChildAcademicYearOrJob4] = useState('')
    const [childIncome4, setChildIncome4] = useState('')
    const [childFatherOFConfession4, setChildFatherOFConfession4] = useState('')
    const [childPhone4, setChildPhone4] = useState('')
    const [childName5, setChildName5] = useState('')
    const [childID5, setChildID5] = useState('')
    const [childstatus5, setChildstatus5] = useState('')
    const [childAcademicYearOrJob5, setChildAcademicYearOrJob5] = useState('')
    const [childIncome5, setChildIncome5] = useState('')
    const [childFatherOFConfession5, setChildFatherOFConfession5] = useState('')
    const [childPhone5, setChildPhone5] = useState('')
    const [personalIncome, setPersonalIncome] = useState('');
    const [husbandSalary, setHusbandSalary] = useState('');
    const [wifeSalary, setWifeSalary] = useState('');
    const [insurancePension, setInsurancePension] = useState('');
    const [solidarityPension, setSolidarityPension] = useState('');
    const [disabilityPension, setDisabilityPension] = useState('');
    const [rationCardMembers, setRationCardMembers] = useState('');
    const [childrenAid, setChildrenAid] = useState('');
    const [externalAid, setExternalAid] = useState('');
    const [monthlyTreatment, setMonthlyTreatment] = useState('');
    const [totalPersonalIncome, setTotalPersonalIncome] = useState('');
    const [churchAid, setChurchAid] = useState('');
    const [saintVerenaMonthly, setSaintVerenaMonthly] = useState('');
    const [anbaAbramMonthly, setAnbaAbramMonthly] = useState('');
    const [golfLandMonthly, setGolfLandMonthly] = useState('');
    const [cathedralMonthly, setCathedralMonthly] = useState('');
    const [shepherdAndLightMonthly, setShepherdAndLightMonthly] = useState('');
    const [otherPlaces, setOtherPlaces] = useState('');
    const [copticAid, setCopticAid] = useState('');
    const [studyAid, setStudyAid] = useState('');
    const [blessingBag, setBlessingBag] = useState('');
    const [totalBlessing, setTotalBlessing] = useState('');
    const [totalIncome, setTotalIncome] = useState('');
    const [electricityWaterGas, setElectricityWaterGas] = useState('');
    const [telephone, setTelephone] = useState('');
    const [oldRent, setOldRent] = useState('');
    const [newRent, setNewRent] = useState('');
    const [familyHouse, setFamilyHouse] = useState('');
    const [familyHomeSeparate, setFamilyHomeSeparate] = useState('');
    const [nutrition, setNutrition] = useState('');
    const [study, setStudy] = useState('');
    const [cleaningSupplies, setCleaningSupplies] = useState(0);
    const [medicine, setMedicine] = useState(0);
    const [transportation, setTransportation] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [committeeDecision, setCommitteeDecision] = useState('');
    const [notes, setNotes] = useState('');
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'اخوة رب') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleChildInputChange = (index, field, value) => {
        switch (index) {
            case 1:
                if (field === 'name') setChildName1(value);
                if (field === 'id') setChildID1(value);
                if (field === 'status') setChildstatus1(value);
                if (field === 'academicYearOrJob') setChildAcademicYearOrJob1(value);
                if (field === 'income') setChildIncome1(value);
                if (field === 'fatherOfConfession') setChildFatherOFConfession1(value);
                if (field === 'phone') setChildPhone1(value);
                break;
            case 2:
                if (field === 'name') setChildName2(value);
                if (field === 'id') setChildID2(value);
                if (field === 'status') setChildstatus2(value);
                if (field === 'academicYearOrJob') setChildAcademicYearOrJob2(value);
                if (field === 'income') setChildIncome2(value);
                if (field === 'fatherOfConfession') setChildFatherOFConfession2(value);
                if (field === 'phone') setChildPhone2(value);
                break;
            case 3:
                if (field === 'name') setChildName3(value);
                if (field === 'id') setChildID3(value);
                if (field === 'status') setChildstatus3(value);
                if (field === 'academicYearOrJob') setChildAcademicYearOrJob3(value);
                if (field === 'income') setChildIncome3(value);
                if (field === 'fatherOfConfession') setChildFatherOFConfession3(value);
                if (field === 'phone') setChildPhone3(value);
                break;
            case 4:
                if (field === 'name') setChildName4(value);
                if (field === 'id') setChildID4(value);
                if (field === 'status') setChildstatus4(value);
                if (field === 'academicYearOrJob') setChildAcademicYearOrJob4(value);
                if (field === 'income') setChildIncome4(value);
                if (field === 'fatherOfConfession') setChildFatherOFConfession4(value);
                if (field === 'phone') setChildPhone4(value);
                break;
            case 5:
                if (field === 'name') setChildName5(value);
                if (field === 'id') setChildID5(value);
                if (field === 'status') setChildstatus5(value);
                if (field === 'academicYearOrJob') setChildAcademicYearOrJob5(value);
                if (field === 'income') setChildIncome5(value);
                if (field === 'fatherOfConfession') setChildFatherOFConfession5(value);
                if (field === 'phone') setChildPhone5(value);
                break;
            default:
                break;
        }
    };
    const handleSubmit = async () => {
        console.log('ssss')
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}god/add`, {
                familyGod,
                familyNumber,
                researchDate,
                godName,
                godID,
                godJob,
                godPhone,
                godFatherOFConfession,
                godAddreess,
                children,
                childName1,
                childID1,
                childstatus1,
                childAcademicYearOrJob1,
                childIncome1,
                childFatherOFConfession1,
                childPhone1,
                childName2,
                childID2,
                childstatus2,
                childAcademicYearOrJob2,
                childIncome2,
                childFatherOFConfession2,
                childPhone2,
                childName3,
                childID3,
                childstatus3,
                childAcademicYearOrJob3,
                childIncome3,
                childFatherOFConfession3,
                childPhone3,
                childName4,
                childID4,
                childstatus4,
                childAcademicYearOrJob4,
                childIncome4,
                childFatherOFConfession4,
                childPhone4,
                childName5,
                childID5,
                childstatus5,
                childAcademicYearOrJob5,
                childIncome5,
                childFatherOFConfession5,
                childPhone5,
                personalIncome,
                husbandSalary,
                wifeSalary,
                insurancePension,
                solidarityPension,
                disabilityPension,
                rationCardMembers,
                childrenAid,
                externalAid,
                monthlyTreatment,
                totalPersonalIncome,
                churchAid,
                saintVerenaMonthly,
                anbaAbramMonthly,
                golfLandMonthly,
                cathedralMonthly,
                shepherdAndLightMonthly,
                otherPlaces,
                copticAid,
                studyAid,
                blessingBag,
                totalBlessing,
                totalIncome,
                electricityWaterGas,
                telephone,
                oldRent,
                newRent,
                familyHouse,
                familyHomeSeparate,
                nutrition,
                study,
                cleaningSupplies,
                medicine,
                transportation,
                totalExpenses,
                committeeDecision,
                notes
            });
            if (response.data.message == "تم إضافة الأسرة بنجاح") {
                alert('تم إضافة الأسرة بنجاح')
            }
            console.log('Response:', response.data.message);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    useEffect(() => {
        verifyAdmin()
    },[])
    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="add-heading mt-5">
                <h1 className="text-center text-3xl font-bold">صفحة اضافة الأسر الجديدة في الكنيسة</h1>
            </div>
            <div className="family-details mt-5 mb-5 flex flex-wrap gap-4 justify-around">
                <div className="text-center">
                    <h1 className="text-2xl">رب الاسرة</h1>
                    <div className="text-center">
                        <select className="w-[340px] border mt-2 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setFamilyGod(e.target.value)
                        }}>
                            <option value="" selected>من فضلك اختار رب الاسرة</option>
                            <option value="زوج">زوج</option>
                            <option value="زوجة">زوجة</option>
                        </select>
                    </div>
                </div>
                <div className="text-center">
                    <h1 className="text-2xl">رقم الاسرة</h1>
                    <div className="text-center">
                        <input onChange={(e) => {
                            setFamilyNumber(e.target.value)
                        }} type="number" className="mt-2 w-[340px] border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" />
                    </div>
                </div>
                <div className="text-center">
                    <h1 className="text-2xl">تاريخ البحث</h1>
                    <div className="text-center">
                        <input onChange={(e) => {
                            setResearchDate(e.target.value)
                        }} type="date" className="mt-2 w-[340px] border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" />
                    </div>
                </div>
            </div>
            <div className="guardance-info">
                <h1 className="text-xl">ادخل معلومات رب الاسرة</h1>
                <div className="flex justify-center gap-4 flex-wrap">
                    <div className="form-group mt-4">
                        <label className="text-xl">الأسم</label> <br />
                        <input type="text" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodName(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">الرقم القومي</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodID(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">الوظيفة</label> <br />
                        <input type="text" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodJob(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">تليفون</label> <br />
                        <input type="text" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodPhone(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اب الاعتراف</label> <br />
                        <input type="text" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodFatherOFConfession(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">العنوان بالتفصيل</label> <br />
                        <input type="text" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGodAddreess(e.target.value)
                        }} />
                    </div>
                </div>
            </div>
            <div className="children-info mt-4">
                <h1 className="text-2xl">بيانات الاولاد</h1>
                <div className="children-number flex items-center gap-4">
                    <h1 className="text-xl mt-4">عدد الاولاد</h1>
                    <input type="number" className="w-[50px] text-center border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => { setChildren(e.target.value) }} />
                </div>
            </div>
            <div>
                {Array.from({ length: children }).map((_, index) => (
                    <div key={index} className="children-input s mt-4 flex flex-wrap justify-center gap-4">
                        <h1 className="w-full text-2xl mt-2">ادخل بيانات الابن \ الابنة رقم {index + 1}</h1>
                        <div className="form-group mt-4">
                            <label className="text-xl">الأسم</label> <br />
                            <input
                                type="text"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childName1 : index === 1 ? childName2 : index === 2 ? childName3 : index === 3 ? childName4 : index === 4 ? childName5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'name', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">الرقم القومي</label> <br />
                            <input
                                type="number"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childID1 : index === 1 ? childID2 : index === 2 ? childID3 : index === 3 ? childID4 : index === 4 ? childID5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'id', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">الحالة الاجتماعية</label> <br />
                            <input
                                type="text"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childstatus1 : index === 1 ? childstatus2 : index === 2 ? childstatus3 : index === 3 ? childstatus4 : index === 4 ? childstatus5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'status', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">السنة الدراسية\الوظيفة</label> <br />
                            <input
                                type="text"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childAcademicYearOrJob1 : index === 1 ? childAcademicYearOrJob2 : index === 2 ? childAcademicYearOrJob3 : index === 3 ? childAcademicYearOrJob4 : index === 4 ? childAcademicYearOrJob5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'academicYearOrJob', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">الدخل</label> <br />
                            <input
                                type="number"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childIncome1 : index === 1 ? childIncome2 : index === 2 ? childIncome3 : index === 3 ? childIncome4 : index === 4 ? childIncome5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'income', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">اب الاعتراف</label> <br />
                            <input
                                type="text"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childFatherOFConfession1 : index === 1 ? childFatherOFConfession2 : index === 2 ? childFatherOFConfession3 : index === 3 ? childFatherOFConfession4 : index === 4 ? childFatherOFConfession5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'fatherOfConfession', e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">التليفون</label> <br />
                            <input
                                type="number"
                                className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={index === 0 ? childPhone1 : index === 1 ? childPhone2 : index === 2 ? childPhone3 : index === 3 ? childPhone4 : index === 4 ? childPhone5 : ''}
                                onChange={(e) => handleChildInputChange(index + 1, 'phone', e.target.value)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="income-details">
                <h1 className="text-2xl">بيانات الدخل</h1>
                <div className="flex flex-wrap gap-4 justify-center">
                    <div className="form-group mt-4">
                        <label className="text-xl">الدخل الشخصي</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setPersonalIncome(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مرتب الزوج</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setHusbandSalary(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مرتب الزوجة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setWifeSalary(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">معاش تأمنيي</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setInsurancePension(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">معاش تكافل و كرامة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setSolidarityPension(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">معاش اعاقة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setDisabilityPension(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">بطاقة التومين (عدد الافراد)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setRationCardMembers(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مساعدات الابناء</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setChildrenAid(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مساعدات خارجية</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setExternalAid(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">علاج شهري</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setMonthlyTreatment(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اجمالي دخل الشخص</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTotalPersonalIncome(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مساعدات و شهريات الكنائس</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setChurchAid(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شهرية (القديسة فيرينا)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setSaintVerenaMonthly(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شهرية (الانبا ابرام)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setAnbaAbramMonthly(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شهرية (ارض الجولف)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setGolfLandMonthly(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شهرية (الكاتدرائية)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setCathedralMonthly(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شهرية (الراعي وام النور)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setShepherdAndLightMonthly(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اماكن اخري\الكنيسة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setOtherPlaces(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">coptic</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setCopticAid(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">بركة دراسة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setStudyAid(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">شنطة بركة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setBlessingBag(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اجمالي البركة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTotalBlessing(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اجمالي الدخل</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTotalIncome(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">كهرباء و مياة و غاز</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setElectricityWaterGas(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">تليفون</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTelephone(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">ايجار قديم</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setOldRent(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">ايجار جديد</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setNewRent(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مع الاسرة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setFamilyHouse(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">منزل العائلة(شفة منفصلة)</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setFamilyHomeSeparate(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">تغذية</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setNutrition(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">دراسة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setStudy(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">ادوات نظافة</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setCleaningSupplies(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">علاج</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setMedicine(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">مواصلات</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTransportation(e.target.value)
                        }} />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">اجمالي المصروفات</label> <br />
                        <input type="number" className="w-[340px] border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setTotalExpenses(e.target.value)
                        }} />
                    </div>
                </div>
            </div>
            <div className="mt-4 w-[80%] m-auto">
                <div className="form-group mt-4">
                    <label className="text-xl">قرار اللجنة</label> <br />
                    <input type="text" className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                        setCommitteeDecision(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">ملاحظات</label> <br />
                    <input type="text" className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                        setNotes(e.target.value)
                    }} />
                </div>
                <div className="form-group mt-6 text-center">
                    <button className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200" onClick={handleSubmit}>أضافة اسرة</button>
                </div>
            </div>
        </div>
    );
}

export default AddFamily;