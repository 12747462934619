import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminFinanceDeleted = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [place, setPlace] = useState('');
    const [type, setType] = useState('');
    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const itemsPerPage = 10;

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };


    const deleteIncome = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف الوارد بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}locker/delete/incomes/${id}`);
                    showSuccessAlert('تم حذف الوارد بنجاح')
                    getIncomes();
                } catch (err) {
                    console.error('Error deleting income:', err);
                    showErrorAlert('حدث خطأ ما اثناء الحذف')
                }
            }
        });
    };

    const deleteExpense = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف المصروف بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}locker/delete/expenses/${id}`);
                    showSuccessAlert('تم حذف المصروف بنجاح')
                    getExpenses();
                } catch (err) {
                    console.error('Error deleting expense:', err);
                    showErrorAlert('حدث خطأ ما اثناء الحذف')
                }
            }
        });
    };

    const restoreIncome = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد ارجاع الوارد ؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، ارجعه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${process.env.REACT_APP_HOST_SERVER}locker/unmark/incomes/${id}`);
                    showSuccessAlert('تم ارجاع الوارد بنجاح')
                    getIncomes();
                } catch (err) {
                    console.error('Error deleting income:', err);
                    showErrorAlert('حدث خطأ ما اثناء الحذف')
                }
            }
        });
    };

    const restoreExpense = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد ارجاع المصروف ؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، ارجعه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${process.env.REACT_APP_HOST_SERVER}locker/unmark/expenses/${id}`);
                    showSuccessAlert('تم ارجاع المصروف بنجاح')
                    getExpenses();
                } catch (err) {
                    console.error('Error deleting expense:', err);
                    showErrorAlert('حدث خطأ ما اثناء الحذف')
                }
            }
        });
    };


    const getIncomes = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}locker/incomes`);
            setIncomes(res.data || []);
        } catch (err) {
            setError('فشل في تحميل الواردات.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const getExpenses = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`);
            setExpenses(res.data || []);
        } catch (err) {
            setError('فشل في تحميل المصروفات.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const filterData = (data, startDate, endDate, place, type) => {
        if (!Array.isArray(data)) return [];
        return data.filter(item => {
            const isWithinDateRange =
                (!startDate || new Date(item.date) >= new Date(startDate)) &&
                (!endDate || new Date(item.date) <= new Date(endDate));
            const isPlaceMatch = !place || (item.place.place && item.place.place.includes(place));
            const isTypeMatch = !type || (item.type && item.type.includes(type));
            return isWithinDateRange && isPlaceMatch && isTypeMatch;
        });
    };

    const filterData2 = (data, endDate, place, type) => {
        const projectStartDate = '2024-10-01';
        if (!Array.isArray(data)) return [];
        return data.filter(item => {
            const isWithinDateRange =
                (!projectStartDate || new Date(item.date) >= new Date(projectStartDate)) &&
                (!endDate || new Date(item.date) <= new Date(endDate));
            const isPlaceMatch = !place || (item.place.place && item.place.place.includes(place));
            const isTypeMatch = !type || (item.type && item.type.includes(type));
            return isWithinDateRange && isPlaceMatch && isTypeMatch;
        });
    };


    const filteredExpenses = filterData(expenses, startDate, endDate, place, type);
    const filteredIncomes = filterData(incomes, startDate, endDate, place, type);

    const filteredExpenses2 = filterData2(expenses, endDate, place, type);
    const filteredIncomes2 = filterData2(incomes, endDate, place, type);

    const totalExpenses = filteredExpenses
        .filter(item => item.deleted === "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const totalIncomes = filteredIncomes
        .filter(item => item.deleted === "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const totalExpenses2 = filteredExpenses2
        .filter(item => item.deleted === "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const totalIncomes2 = filteredIncomes2
        .filter(item => item.deleted === "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const difference = totalIncomes2 - totalExpenses2;

    const allTotalIncomes = incomes
        .filter(item => item.deleted !== "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const allTotalExpenses = expenses
        .filter(item => item.deleted !== "yes")
        .reduce((acc, item) => acc + item.price, 0);

    const allDifference = allTotalIncomes - allTotalExpenses;

    useEffect(() => {
        verifyAdmin();
        getExpenses();
        getIncomes();
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 text-black p-6">
            <div className="print:hidden">
                <Navbar />
                <h1 className="text-4xl font-bold mb-10 text-center text-blue-600">صفحة المعاملات المالية</h1>
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6 print:break-after-page">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">عمل تقرير</h2>
                    <div className="flex flex-wrap justify-between mb-6 gap-4">
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">من تاريخ</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{startDate}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">إلى تاريخ</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{endDate}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">المكان</label>
                            <input
                                type="text"
                                placeholder="بحث بالمكان"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{place}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">النوع</label>
                            <input
                                type="text"
                                placeholder="بحث بالنوع"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{type}</p>
                        </div>
                        <div className="w-full md:w-1/4 flex items-center">
                            <button className='px-4 py-2 text-white bg-lime-500 w-full rounded-md' onClick={() => { window.print() }}>طباعة</button>
                        </div>
                    </div>
                </div>

                {error && <div className="bg-red-200 p-4 rounded-lg text-red-800 mb-6 text-center">{error}</div>}
                {loading && <div className="text-center">جاري تحميل البيانات...</div>}
                <div className="overflow-x-auto">
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-4">
                            <h2 className="text-2xl font-semibold mb-4 text-purple-600 text-center">جدول المصروفات</h2>
                            <table className="w-full table-auto border border-gray-300">
                                <thead className="bg-purple-100">
                                    <tr>
                                        <th className="border-b p-3">التاريخ</th>
                                        <th className="border-b p-3">رقم الوصل</th>
                                        <th className="border-b p-3">المكان</th>
                                        <th className="border-b p-3">النوع</th>
                                        <th className="border-b p-3">الثمن</th>
                                        <th className="border-b p-3 print:hidden">اجرائات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.filter((item) => item.deleted === "yes").map((expense, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border-b p-3">{new Date(expense.date).toISOString().slice(0, 10)}</td>
                                            <td className="border-b p-3">{expense.serialNumber}</td>
                                            <td className="border-b p-3">{expense.place.place}</td>
                                            <td className="border-b p-3">{expense.type}</td>
                                            <td className="border-b p-3">{expense.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="border-b p-3">
                                                <button
                                                    onClick={() => deleteExpense(expense._id)}
                                                    className="bg-red-500 text-white p-2 rounded"
                                                >
                                                    حذف
                                                </button>
                                                <button
                                                    onClick={() => restoreExpense(expense._id)}
                                                    className="bg-lime-500 mx-4 text-white p-2 rounded"
                                                >
                                                    ارجاع
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-4">

                            <h2 className="text-2xl font-semibold mb-4 text-green-600 text-center">جدول الواردات</h2>
                            <table className="w-full table-auto border border-gray-300">
                                <thead className="bg-green-100">
                                    <tr>
                                        <th className="border-b p-3">التاريخ</th>
                                        <th className="border-b p-3">رقم الوصل</th>
                                        <th className="border-b p-3">المكان</th>
                                        <th className="border-b p-3">النوع</th>
                                        <th className="border-b p-3">الثمن</th>
                                        <th className="border-b p-3 print:hidden">اجرائات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {incomes.filter((item) => item.deleted === "yes").map((income, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border-b p-3">{new Date(income.date).toISOString().slice(0, 10)}</td>
                                            <td className="border-b p-3">{income.serialNumber}</td>
                                            <td className="border-b p-3">{income.place.place}</td>
                                            <td className="border-b p-3">{income.type ? income.type : 'لا يوجد'}</td>
                                            <td className="border-b p-3">{income.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="border-b p-3">
                                                <button
                                                    onClick={() => deleteIncome(income._id)}
                                                    className="bg-red-500 text-white p-2 rounded"
                                                >
                                                    حذف
                                                </button>
                                                <button
                                                    onClick={() => restoreIncome(income._id)}
                                                    className="bg-lime-500 mx-4 text-white p-2 rounded"
                                                >
                                                    ارجاع
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden print:block">

                <Navbar />
                <h1 className="text-4xl font-bold mb-10 text-center text-blue-600">صفحة المعاملات المالية</h1>
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6 print:break-after-page">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">عمل تقرير</h2>
                    <div className="flex flex-wrap justify-between mb-6 gap-4">
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">من تاريخ</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{startDate}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">إلى تاريخ</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{endDate}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">المكان</label>
                            <input
                                type="text"
                                placeholder="بحث بالمكان"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{place}</p>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label className="block print:inline text-gray-600 mb-2">النوع</label>
                            <input
                                type="text"
                                placeholder="بحث بالنوع"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <p className='hidden print:inline print:mr-3'>{type}</p>
                        </div>
                        <div className="w-full md:w-1/4 flex items-center">
                            <button className='px-4 py-2 text-white bg-lime-500 w-full rounded-md' onClick={() => { window.print() }}>طباعة</button>
                        </div>
                    </div>
                    <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold text-center text-blue-700">الفرق: {allDifference} وحدة</h3>
                        <h3 className="text-xl font-semibold text-center text-blue-700">الفرق: {difference} وحدة</h3>
                    </div>

                    <div className='print:block hidden'>
                        {
                            (place || type || startDate || endDate) ?
                                <div>
                                    <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-center text-blue-700">اجمالي المصروفات: {totalExpenses} وحدة</h3>
                                    </div>
                                    <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-center text-blue-700">اجمالي الواردات: {totalIncomes} وحدة</h3>
                                    </div>
                                </div> :
                                <div>
                                    <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-center text-blue-700">اجمالي المصروفات: {totalExpenses} وحدة</h3>
                                    </div>
                                    <div className="mt-6 bg-gray-200 p-4 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-center text-blue-700">اجمالي الواردات: {totalIncomes} وحدة</h3>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                {error && <div className="bg-red-200 p-4 rounded-lg text-red-800 mb-6 text-center">{error}</div>}
                {loading && <div className="text-center">جاري تحميل البيانات...</div>}
                <div className="overflow-x-auto">
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-4">
                            <div className="mt-4 text-lg font-semibold text-center print:hidden">
                                {(place || startDate || endDate || type) ? (
                                    <p>اجمالي المصروفات: {totalExpenses.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} وحدة</p>
                                ) : <p>اجمالي المصروفات: {allTotalExpenses.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} وحدة</p>}
                            </div>
                            <h2 className="text-2xl font-semibold mb-4 text-purple-600 text-center">جدول المصروفات</h2>
                            <table className="w-full table-auto border border-gray-300">
                                <thead className="bg-purple-100">
                                    <tr>
                                        <th className="border-b p-3">التاريخ</th>
                                        <th className="border-b p-3">رقم الوصل</th>
                                        <th className="border-b p-3">المكان</th>
                                        <th className="border-b p-3">النوع</th>
                                        <th className="border-b p-3">الثمن</th>
                                        <th className="border-b p-3 print:hidden">اجرائات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredExpenses.filter((item) => item.deleted === "yes").map((expense, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border-b p-3">{new Date(expense.date).toISOString().slice(0, 10)}</td>
                                            <td className="border-b p-3">{expense.serialNumber}</td>
                                            <td className="border-b p-3">{expense.place.place}</td>
                                            <td className="border-b p-3">{expense.type}</td>
                                            <td className="border-b p-3">{expense.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="border-b p-3">
                                                <button
                                                    onClick={() => deleteExpense(expense._id)}
                                                    className="bg-red-500 text-white p-2 rounded"
                                                >
                                                    حذف
                                                </button>
                                                <button
                                                    onClick={() => restoreExpense(expense._id)}
                                                    className="bg-lime-500 mx-4 text-white p-2 rounded"
                                                >
                                                    ارجاع
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-4">
                            <div className="mt-4 text-lg font-semibold text-center print:hidden">
                                {(place || startDate || endDate || type) ? (
                                    <p>اجمالي الواردات: {totalIncomes.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} وحدة</p>
                                ) :
                                    <p>اجمالي الواردات: {allTotalIncomes.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} وحدة</p>
                                }
                            </div>
                            <h2 className="text-2xl font-semibold mb-4 text-green-600 text-center">جدول الواردات</h2>
                            <table className="w-full table-auto border border-gray-300">
                                <thead className="bg-green-100">
                                    <tr>
                                        <th className="border-b p-3">التاريخ</th>
                                        <th className="border-b p-3">رقم الوصل</th>
                                        <th className="border-b p-3">المكان</th>
                                        <th className="border-b p-3">النوع</th>
                                        <th className="border-b p-3">الثمن</th>
                                        <th className="border-b p-3 print:hidden">اجرائات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredIncomes.filter((item) => item.deleted === "yes").map((income, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="border-b p-3">{new Date(income.date).toISOString().slice(0, 10)}</td>
                                            <td className="border-b p-3">{income.serialNumber}</td>
                                            <td className="border-b p-3">{income.place.place}</td>
                                            <td className="border-b p-3">{income.type ? income.type : 'لا يوجد'}</td>
                                            <td className="border-b p-3">{income.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="border-b p-3">
                                                <button
                                                    onClick={() => deleteIncome(income._id)}
                                                    className="bg-red-500 text-white p-2 rounded"
                                                >
                                                    حذف
                                                </button>
                                                <button
                                                    onClick={() => restoreIncome(income._id)}
                                                    className="bg-lime-500 mx-4 text-white p-2 rounded"
                                                >
                                                    ارجاع
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default AdminFinanceDeleted;
