import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
    const [place, setPlace] = useState('');
    const [category, setCategory] = useState('');
    const [places, setPlaces] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading,setLoading] = useState('')

    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/category`, { place, category });
            setSuccess(response.data.message);
            setError(null);
            setPlace('');
            setCategory('');
            setLoading(false)
        } catch (err) {
            console.log(err)
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="add-heading mt-5">
                <h1 className="text-center text-3xl font-bold">صفحة اضافة الفئات الجديدة في الكنيسة</h1>
            </div>
            <div className="form w-[50%] m-auto">
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-4">
                        <label className="text-xl">المكان المستهدف</label>
                        <select
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setPlace(e.target.value)}
                            value={place}
                        >
                            <option value="">من فضلك اختار المكان</option>
                            {places && places.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.place}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">الفئة الجديدة</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}
                        />
                    </div>
                    {error && (
                        <div className="mt-4 text-red-500 text-center">
                            {error}
                        </div>
                    )}
                    {success && (
                        <div className="mt-4 text-green-500 text-center">
                            {success}
                        </div>
                    )}
                    <div className="submit mt-6 text-center">
                        <button
                            type="submit"
                            className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                            disabled={loading}  
                        >
                            أضافة الفئة
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCategory;
