import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const SearchFamily = () => {
    const [familyName, setFamilyName] = useState('');
    const [familyData, setFamilyData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [role,setRole] = useState(true)
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'اخوة رب') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };
    const verifyAdmin2 = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify2`, { token });
            if (res.data !== 'كاهن') {
                setRole(false)
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSearch = async () => {
        if (!familyName) {
            setError('رقم الأسرة مطلوب');
            return;
        }
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}god/get`, { godName:familyName});
            setFamilyData(response.data[0]);
            console.log(response.data)
        } catch (err) {
            setError('فشل في جلب البيانات. تأكد من الرقم المدخل أو حاول لاحقًا.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin()
        verifyAdmin2()
    })
    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="search">
                <h1 className="text-3xl text-center">أهلاً في صفحة البحث عن الأسرة</h1>
                <div className="form-group mt-4 w-[70%] m-auto">
                    <label className="text-xl">رب الاسرة</label> <br />
                    <input
                        type="text"
                        className="w-full border mt-3 px-4 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setFamilyName(e.target.value)}
                    />
                </div>
                <div className="form-group mt-6 text-center">
                    <button
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSearch}
                    >
                        ابحث
                    </button>
                </div>

                {loading && <p className="text-center mt-4">جاري التحميل...</p>}
                {error && <p className="text-center mt-4 text-red-500">{error}</p>}

                {familyData && (
                    <div className="mt-8 space-y-4 bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold mb-4">تفاصيل الأسرة</h2>

                        {/* Basic family details */}
                        <p className="mb-2"><strong>اسم الأسرة:</strong>
                            <input type="text" value={familyData.familyGod} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>رقم الأسرة:</strong>
                            <input type="text" value={familyData.familyNumber} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>تاريخ البحث:</strong>
                            <input type="date" value={new Date(familyData.researchDate).toISOString().split('T')[0]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>

                        {/* God (head of family) details */}
                        <h3 className="text-xl font-bold mb-2">تفاصيل رب الأسرة</h3>
                        <p className="mb-2"><strong>اسم رب الأسرة:</strong>
                            <input type="text" value={familyData.godName} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>رقم بطاقة رب الأسرة:</strong>
                            <input type="text" value={familyData.godID} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>وظيفة رب الأسرة:</strong>
                            <input type="text" value={familyData.godJob} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>رقم هاتف رب الأسرة:</strong>
                            <input type="text" value={familyData.godPhone} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>أب الاعتراف:</strong>
                            <input type="text" value={familyData.godFatherOFConfession} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>عنوان رب الأسرة:</strong>
                            <input type="text" value={familyData.godAddreess} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>

                        {/* Children details (repeat for each child) */}
                        <h3 className="text-xl font-bold mb-2">تفاصيل الأبناء</h3>
                        <p className="mb-2 mt-2"><strong>عدد الابناء</strong>
                            <input type="text" value={familyData.children} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        {[1, 2, 3, 4, 5].map((i) => (
                            familyData[`childName${i}`] && (
                                <div key={i}>
                                    <p className="mb-2"><strong>اسم الابن {i}:</strong>
                                        <input type="text" value={familyData[`childName${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>رقم بطاقة الابن {i}:</strong>
                                        <input type="text" value={familyData[`childID${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>حالة الابن {i}:</strong>
                                        <input type="text" value={familyData[`childstatus${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>وظيفة/سنة دراسية الابن {i}:</strong>
                                        <input type="text" value={familyData[`childAcademicYearOrJob${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>دخل الابن {i}:</strong>
                                        <input type="text" value={familyData[`childIncome${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>أب الاعتراف الابن {i}:</strong>
                                        <input type="text" value={familyData[`childFatherOFConfession${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                    <p className="mb-2"><strong>رقم هاتف الابن {i}:</strong>
                                        <input type="text" value={familyData[`childPhone${i}`]} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                                    </p>
                                </div>
                            )
                        ))}

                        {/* Income details */}
                        <h3 className="text-xl font-bold mb-2">التفاصيل المالية و المساعدات</h3>
                        <p className="mb-2"><strong>الدخل الشخصي:</strong>
                            <input type="text" value={familyData.personalIncome} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مرتب الزوج:</strong>
                            <input type="text" value={familyData.husbandSalary} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مرتب الزوجة:</strong>
                            <input type="text" value={familyData.wifeSalary} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>معاش التأمين:</strong>
                            <input type="text" value={familyData.insurancePension} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>معاش التضامن:</strong>
                            <input type="text" value={familyData.solidarityPension} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>معاش العجز:</strong>
                            <input type="text" value={familyData.disabilityPension} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>عدد أفراد بطاقة التموين:</strong>
                            <input type="text" value={familyData.rationCardMembers} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة الأبناء:</strong>
                            <input type="text" value={familyData.childrenAid} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>المساعدة الخارجية:</strong>
                            <input type="text" value={familyData.externalAid} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف العلاج الشهري:</strong>
                            <input type="text" value={familyData.monthlyTreatment} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>اجمالي الدخل الشخصي:</strong>
                            <input type="text" value={familyData.totalPersonalIncome} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة الكنيسة:</strong>
                            <input type="text" value={familyData.churchAid} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة سانت فيرينا:</strong>
                            <input type="text" value={familyData.saintVerenaMonthly} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة أنبا ابرام:</strong>
                            <input type="text" value={familyData.anbaAbramMonthly} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة ارض الجولف الشهرية:</strong>
                            <input type="text" value={familyData.golfLandMonthly} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة الكاتدرائية الشهرية:</strong>
                            <input type="text" value={familyData.cathedralMonthly} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة الراعي والنور الشهرية:</strong>
                            <input type="text" value={familyData.shepherdAndLightMonthly} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدات الأماكن الأخرى:</strong>
                            <input type="text" value={familyData.otherPlaces} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة كوبتك:</strong>
                            <input type="text" value={familyData.copticAid} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مساعدة الدراسة:</strong>
                            <input type="text" value={familyData.studyAid} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>شنطة البركة:</strong>
                            <input type="text" value={familyData.blessingBag} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>إجمالي البركة:</strong>
                            <input type="text" value={familyData.totalBlessing} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>اجمالي الدخل:</strong>
                            <input type="text" value={familyData.totalIncome} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>

                        {/* Expenses details */}
                        <h3 className="text-xl font-bold mb-2">تفاصيل المصروفات</h3>
                        <p className="mb-2"><strong>مصاريف الكهرباء والمياه والغاز:</strong>
                            <input type="text" value={familyData.electricityWaterGas} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف التليفون:</strong>
                            <input type="text" value={familyData.telephone} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف الإيجار القديم:</strong>
                            <input type="text" value={familyData.oldRent} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف الإيجار الجديد:</strong>
                            <input type="text" value={familyData.newRent} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong> مصاريف المنزل العائلي:</strong>
                            <input type="text" value={familyData.familyHouse} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف المنزل المنفصل:</strong>
                            <input type="text" value={familyData.familyHomeSeparate} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف التغذية:</strong>
                            <input type="text" value={familyData.nutrition} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف الدراسة:</strong>
                            <input type="text" value={familyData.study} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف ادوات النظافة:</strong>
                            <input type="text" value={familyData.cleaningSupplies} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف العلاج:</strong>
                            <input type="text" value={familyData.medicine} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>مصاريف المواصلات:</strong>
                            <input type="text" value={familyData.transportation} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        <p className="mb-2"><strong>قيمة اجمالي المصروفات:</strong>
                            <input type="text" value={familyData.totalExpenses} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>

                        {/* Decision and Notes */}
                        <p className="mb-2"><strong>قرار اللجنة:</strong>
                            <input type="text" value={familyData.committeeDecision} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" readOnly />
                        </p>
                        {
                            role ?
                                <p className="mb-2"><strong>ملاحظات:</strong>
                                <textarea value={familyData.notes} className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-lime-500" rows="4" readOnly />
                                </p>
                                    : ''
                        }
                    </div>
                )}

            </div>
        </div>
    );
}

export default SearchFamily;
