import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UpdateCategory = () => {
    const [categories, setCategories] = useState([]);
    const [places, setPlaces] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newcategory, setNewcategory] = useState('');
    const [selectedPlace, setSelectedPlace] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const categoriesPerPage = 20;

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/category`);
            setCategories(response.data.data);
            console.log(response.data.data)
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchPlaces = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`);
            setPlaces(response.data.data);
        } catch (error) {
            console.error('Error fetching places:', error);
        }
    };

    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setNewcategory(category.category);
        setSelectedPlace(category.place.place);
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/category`, {
                _id: selectedCategory._id,
                category: newcategory,
                place: selectedPlace
            });
            setSelectedCategory(null);
            fetchCategories();
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const filteredCategories = categories.filter((category) =>
        category.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastCategory = currentPage * categoriesPerPage;
    const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
    const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])
    useEffect(() => {
        fetchCategories();
        fetchPlaces();
    }, []);

    return (
        <div className="p-6">
            <h1 className="text-3xl text-yellow-500 mb-6">إدارة الفئات</h1>

            <input
                type="text"
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                placeholder="ابحث عن الفئة"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <table className="w-full text-right">
                <thead>
                    <tr>
                        <th className="text-yellow-500 p-2">اسم الفئة</th>
                        <th className="text-yellow-500 p-2">المكان</th>
                        <th className="text-yellow-500 p-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCategories.map((category) => (
                        <tr key={category._id}>
                            <td className="p-2">{category.category}</td>
                            <td className="p-2">{category.place.place}</td>
                            <td className="p-2">
                                <button
                                    onClick={() => handleEditClick(category)}
                                    className="text-yellow-500 underline"
                                >
                                    تعديل
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mt-4">
                <ul className="flex justify-center">
                    {Array.from({ length: Math.ceil(filteredCategories.length / categoriesPerPage) }, (_, index) => (
                        <li key={index} className="mx-1">
                            <button
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 border ${currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'border-yellow-500 text-yellow-500'}`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {selectedCategory && (
                <div className="mt-6">
                    <h2 className="text-xl text-yellow-500 mb-4">تعديل الفئة</h2>

                    <div className="mb-4">
                        <label className="text-yellow-500">اسم الفئة:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newcategory}
                            onChange={(e) => setNewcategory(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">المكان:</label>
                        <select
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={selectedPlace}
                            onChange={(e) => setSelectedPlace(e.target.value) 
                            }
                        >
                            <option value="">اختر المكان</option>
                            {places.map((place) => (
                                <option key={place._id} value={place._id}>
                                    {place.place}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button
                        onClick={handleSave}
                        className="text-yellow-500 underline mt-4"
                    >
                        حفظ التعديلات
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpdateCategory;
