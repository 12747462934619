import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const AddChild = () => {
    const [childName, setChildName] = useState('');
    const [childBirthDate, setChildBirthDate] = useState('');
    const [childAge, setChildAge] = useState('');
    const [childMotherPhone, setChildMotherPhone] = useState('');
    const [childAddress, setChildAddress] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { place } = useParams();
    const navigate = useNavigate('');
    const [loading, setLoading] = useState(false);

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        })
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };



    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!childName || !childBirthDate || !childAge || !childMotherPhone) {
            setLoading(false);
            return showRequiredAlert('تأكد من ملئ جميع البيانات')
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}nursery/add`, {
                childName,
                childBirthDate,
                childAge,
                childMotherPhone,
                childAddress
            });
            showSuccessAlert('تمت إضافة الطفل بنجاح')
            setSuccess("تمت إضافة الطفل بنجاح");
            setLoading(false);
            setError(null);
            setChildName('');
            setChildBirthDate('');
            setChildAge('');
            setChildMotherPhone('');
            setChildAddress('');
        } catch (err) {
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما تأكد من صحة البيانات'])
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما تأكد من صحة البيانات']);
            console.log(err)
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    const calculateAge = (birthDate) => {
        const currentDate = new Date();
        const birth = new Date(birthDate);

        let ageInMonths = (currentDate.getFullYear() - birth.getFullYear()) * 12;
        ageInMonths -= birth.getMonth();
        ageInMonths += currentDate.getMonth();

        return ageInMonths < 0 ? 0 : ageInMonths;
    };

    const handleBirthDateChange = (e) => {
        const birthDate = e.target.value;
        setChildBirthDate(birthDate);
        setChildAge(calculateAge(birthDate));
    };



    useEffect(() => {
        verifyAdmin();
    }, []);
    return (
        <div>
            <div className="px-4">
                <Navbar />
            </div>
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">اسم الطفل</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={childName}
                        onChange={(e) => setChildName(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">تاريخ ميلاد الطفل</label>
                    <input
                        type="date"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={childBirthDate}
                        onChange={handleBirthDateChange}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">عمر الطفل (بالشهور)</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={childAge}
                        readOnly
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">رقم الهاتف </label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={childMotherPhone}
                        onChange={(e) => setChildMotherPhone(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">العنوان </label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={childAddress}
                        onChange={(e) => setChildAddress(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        أضافة طفل
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddChild;
