import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const Home = () => {
    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="landing-heading mt-10 text-center">
                <h1 className="text-3xl font-bold leading-relaxed">
                    أهلا بكم في موقع كنيسة ماري جرجس المذبح 
                </h1>
            </div>
            <div className="landing-body flex justify-center mt-16">
                <Link to={'/login'}>
                    <button className="bg-[#FFB800] text-white py-4 px-12 rounded-full text-2xl shadow-lg hover:cursor-pointer hover:bg-[#ffb700a6] duration-200">
                        ابدأ الأن
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Home;
