import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const SalesAndReturnsPage = () => {
    const { place } = useParams()
    const [salesProduct, setSalesProduct] = useState('');
    const [salesQuantity, setSalesQuantity] = useState('');
    const [code, setCode] = useState('')
    const [returncode, setReturnCode] = useState('')

    const [returnsQuantity, setReturnsQuantity] = useState('');

    const [products, setProducts] = useState([]);
    const [sales, setSales] = useState([])
    const [returns, setReturns] = useState([])
    const navigate = useNavigate()

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };


    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token')
        if (!token) {
            navigate('/login')
        }
        axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token })
            .then((res) => {
                if (place != res.data) {
                    navigate('/login')
                }
            })
            .catch((err) => {
                console.error(err);
                navigate('/login')
            });
    };

    const fetchProducts = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/product/${place}`)
            .then((res) => {
                setProducts(res.data.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchSalesByDate = async () => {
        const todayDate = new Date().toISOString().split('T')[0];
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/sales/${todayDate}`);
            setSales(response.data.data);
            console.log(response.data.data)
        } catch (error) {
            console.error("Error fetching sales data:", error);
        }
    };

    const fetchReturnsByDate = async () => {
        const todayDate = new Date().toISOString().split('T')[0]; 
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/returns/${todayDate}`);
            setReturns(response.data.data);
        } catch (error) {
            console.error("Error fetching returns data:", error);
        }
    };

    const handleSalesSubmit = (item, e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST_SERVER}add/sales`, {
            Sales_Code: code,
            Sales_product: item.product,
            Sales_Quantity: salesQuantity,
            Sales_Price: item.sellPrice,
            Sales_Place: place
        })
            .then((res) => {
                showSuccessAlert(res.data.message)
                setCode('')
                setSalesQuantity('')
                fetchSalesByDate()
                fetchProducts()
            })
            .catch((err) => {
                console.error(err);
                showErrorAlert('حدث خطأ أثناء الإضافة')
            });
    };

    const handleReturnsSubmit = (item,e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST_SERVER}add/returns`, {
            Returns_product: item.product,
            Returns_Quantity: returnsQuantity,
            Returns_Price: item.sellPrice,
            Returns_Place: place
        })
            .then((res) => {
                showSuccessAlert(res.data.message)
                setReturnCode('')
                fetchReturnsByDate()
                fetchSalesByDate()
                setReturnsQuantity('')
                fetchProducts()
            })
            .catch((err) => {
                console.error(err);
                showErrorAlert(err.response.data.message)
            });
    };

    const today = new Date().toLocaleDateString('ar-EG', {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    useEffect(() => {
        verifyAdmin();
        fetchProducts();
        fetchSalesByDate();
        fetchReturnsByDate();
    }, [])
    return (
        <div className="bg-white text-black min-h-screen">
            <Navbar />
            <div className="max-w-4xl mx-auto p-8">
                <h1 className="text-3xl font-bold text-center mb-6">أهلاً {place}</h1>
                <p className="text-center mb-4">{today}</p>

                {/* Sales Section */}
                <div className="mb-12 p-6 rounded-lg shadow-lg bg-gray-50">
                    <h2 className="text-2xl font-bold mb-4 text-blue-600">المبيعات</h2>

                    <form>
                        <div className="mb-4">
                            <label className="block mb-2">ادخل كود المنتج</label>
                            <input
                                type="text"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={code}
                                onChange={(e) => { setCode(e.target.value) }}
                            />
                        </div>

                        {code && (
                            products && products.filter(item => item.code === code).map((product, index) => (
                                <>
                                    <div className="mb-4">
                                        <label className="block mb-2"> اسم الصنف</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 rounded w-full"
                                            value={product.product}
                                            onChange={() => setSalesProduct(product.product)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2">الكمية</label>
                                        <input
                                            type="number"
                                            className="border border-gray-300 p-2 rounded w-full"
                                            value={salesQuantity}
                                            placeholder={`الكمية المتوفرة: ${products.find(item => item.product === product.product)?.quantity || 'لا يوجد'}`}
                                            onChange={(e) => { setSalesQuantity(e.target.value) }}
                                        />
                                    </div>
                                    {salesQuantity &&
                                        <div className="mt-4">
                                            <p className="text-lg">سعر الصنف للوحدة: <span className="font-bold text-green-600">{product.sellPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                            <p className="text-lg">الكمية: <span className="font-bold text-green-600">{salesQuantity}</span></p>
                                            <p className="text-lg">السعر الكلي: <span className="font-bold text-green-600">{(salesQuantity * product.sellPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                            <div className="submit mt-6 text-center w-full">
                                                <button onClick={(e) => { handleSalesSubmit(product, e) }} className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">
                                                    بيع الان
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))
                        )}
                    </form>
                </div>

                {/* What I Sold Section */}
                <div className="mb-12 p-6 rounded-lg shadow-lg bg-gray-50 h-[300px] overflow-y-scroll">
                    <h2 className="text-2xl font-bold mb-4 text-green-600">ما قمت ببيعه</h2>
                    {
                        sales && sales.filter((item) => item.Sales_Place === place).map((item, index) => (
                            <>
                                <p className="text-lg mt-3">العملية رقم: {index + 1} </p>
                                <p className="text-lg">الصنف: <span className="font-bold text-green-600">{item.Sales_product || 'تم الارجاع'}</span></p>
                                <p className="text-lg">الكمية: <span className="font-bold text-green-600">{item.Sales_Quantity || 'تم الارجاع'}</span></p>
                                <p className="text-lg">السعر: <span className="font-bold text-green-600">{item.Sales_Price || 'تم الارجاع'}</span></p>
                            </>
                        ))
                    }
                </div>

                {/* Returns Section */}
                <div className="mb-12 p-6 rounded-lg shadow-lg bg-gray-50">
                    <h2 className="text-2xl font-bold mb-4 text-red-600">الراجعات</h2>

                    <form >
                        <div className="mb-4">
                            <label className="block mb-2">ادخل كود المنتج</label>
                            <input
                                type="text"
                                className="border border-gray-300 p-2 rounded w-full"
                                value={returncode}
                                onChange={(e) => { setReturnCode(e.target.value) }}
                            />
                        </div>
                        {returncode  && (
                            products && products.filter(item => item.code === returncode).map((product, index) => (
                                <>
                                    <div className="mb-4">
                                        <label className="block mb-2"> اسم الصنف</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 rounded w-full"
                                            value={product.product}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2">الكمية</label>
                                        <input
                                            type="number"
                                            className="border border-gray-300 p-2 rounded w-full"
                                            value={returnsQuantity}
                                            placeholder={`الكمية المتوفرة: ${sales.find(item => item.Sales_Code === returncode)?.Sales_Quantity || 'لا يوجد'}`}
                                            onChange={(e) => { setReturnsQuantity(e.target.value) }}
                                        />
                                    </div>
                                    {returnsQuantity &&
                                        <div className="mt-4">
                                            <p className="text-lg">سعر الصنف للوحدة: <span className="font-bold text-green-600">{product.sellPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                            <p className="text-lg">الكمية: <span className="font-bold text-green-600">{returnsQuantity}</span></p>
                                            <p className="text-lg">السعر الكلي: <span className="font-bold text-green-600">{(returnsQuantity * product.sellPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                            <div className="submit mt-6 text-center w-full">
                                                <button onClick={(e) => { handleReturnsSubmit(product, e) }} className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">
                                                    رجع الان
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))
                        )}
                    </form>
                </div>

                {/* What I Returned Section */}
                <div className="p-6 rounded-lg shadow-lg bg-gray-50 h-[300px] overflow-y-scroll">
                    <h2 className="text-2xl font-bold mb-4 text-yellow-600">ما قمت بإرجاعه</h2>
                    {
                        returns && returns.filter((item) => item.Returns_Place === place).map((item, index) => (
                            <>
                                <p className="text-lg mt-3">العملية رقم: {index + 1}</p>
                                <p className="text-lg">الصنف: <span className="font-bold text-green-600">{item.Returns_product || 'لم يتم البيع بعد'}</span></p>
                                <p className="text-lg">الكمية: <span className="font-bold text-green-600">{item.Returns_Quantity || 'لم يتم البيع بعد'}</span></p>
                                <p className="text-lg">السعر: <span className="font-bold text-green-600">{item.Returns_Price || 'لم يتم البيع بعد'}</span></p>
                            </>
                        ))
                    }</div>
            </div>
            {
                (place === 'مكتبة' || place === 'معرض') ?
                    <div className='text-start w-[90%] m-auto'>
                        <Link to={`/salesPage/` + place}>
                            <button className='text-2xl bg-yellow-500 px-6 py-3 rounded-lg text-white hover:bg-yellow-600 duration-200'> اذهب الي المرتجع</button>
                        </Link>
                    </div> : ''
            }
        </div>
    );
};

export default SalesAndReturnsPage;
