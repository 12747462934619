import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";

const NonWorkerSalary = () => {
    const [monthName, setMonthName] = useState('');
    const [salaries, setSalaries] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [searchDepartment, setSearchDepartment] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [date, setDate] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalBasic, setTotalBasic] = useState(0);
    const [totalAllowances, setTotalAllowances] = useState(0);
    const [totalBagAllowance, setTotalBagAllowance] = useState(0);
    const [totalIncentives, setTotalIncentives] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalAdvanceMonthly, setTotalAdvanceMonthly] = useState(0);
    const [totalOtherDeductions, setTotalOtherDeductions] = useState(0);
    const [totalInsuranceDeduction, setTotalInsuranceDeduction] = useState(0);
    const [totalPenaltyDeduction, setTotalPenaltyDeduction] = useState(0);
    const [totalAdvanceDeduction, setTotalAdvanceDeduction] = useState(0);
    const [netSalary, setNetSalary] = useState('')


    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const fetchSalaries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}salary`);
            const salaryData = response.data.data;

            const calculatedSalaries = salaryData.filter((item)=> item.working == false).map((salary) => {
                const totalSalary =
                    (parseFloat(salary.basic) || 0) +
                    (parseFloat(salary.allowances) || 0) +
                    (parseFloat(salary.bagAllowance) || 0) +
                    (parseFloat(salary.incentives) || 0) +
                    (parseFloat(salary.overtime) || 0);

                const totalDeductions =
                    (parseFloat(salary.advanceMonthly) || 0) +
                    (parseFloat(salary.otherDeductions) || 0) +
                    (parseFloat(salary.insuranceDeduction) || 0) +
                    (parseFloat(salary.penaltyDeduction) || 0) +
                    (parseFloat(salary.advanceDeduction) || 0);

                const netSalary = totalSalary - totalDeductions;

                return {
                    ...salary,
                    totalSalary,
                    totalDeductions,
                    netSalary
                };
            });

            setSalaries(calculatedSalaries);
            setFilterData(calculatedSalaries);
        } catch (error) {
            console.error('Error fetching salaries:', error);
        }
    };

    const handleFieldChange = (e, index, field) => {
        const updatedSalaries = [...filterData];
        updatedSalaries[index][field] = e.target.value;

        // Recalculate totalSalary, totalDeductions, and netSalary on field change
        updatedSalaries[index].totalSalary =
            (parseFloat(updatedSalaries[index].basic) || 0) +
            (parseFloat(updatedSalaries[index].allowances) || 0) +
            (parseFloat(updatedSalaries[index].bagAllowance) || 0) +
            (parseFloat(updatedSalaries[index].incentives) || 0) +
            (parseFloat(updatedSalaries[index].overtime) || 0);

        updatedSalaries[index].totalDeductions =
            (parseFloat(updatedSalaries[index].advanceMonthly) || 0) +
            (parseFloat(updatedSalaries[index].otherDeductions) || 0) +
            (parseFloat(updatedSalaries[index].insuranceDeduction) || 0) +
            (parseFloat(updatedSalaries[index].penaltyDeduction) || 0) +
            (parseFloat(updatedSalaries[index].advanceDeduction) || 0);

        updatedSalaries[index].netSalary =
            updatedSalaries[index].totalSalary - updatedSalaries[index].totalDeductions;

        setFilterData(updatedSalaries);
    };

    const handleSubmit = async (id, index) => {
        const updatedSalary = filterData[index];
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}salary/update/${id}`, updatedSalary);
            setEditIndex(null);
        } catch (error) {
            console.error('Error updating salary:', error);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    const handleFilter = () => {
        let filtered = salaries;
        if (searchName) {
            filtered = filtered.filter((salary) => salary.name.includes(searchName) && salary.working == false);
        }
        if (searchDepartment) {
            filtered = filtered.filter((salary) => salary.departement.includes(searchDepartment) && salary.working == false);
            const totalNetSalary = filtered.reduce((accumulator, salary) => {
                return accumulator + salary.netSalary;
            }, 0);
            setNetSalary(totalNetSalary)
        }
        setFilterData(filtered);
    };

    const openModal = (index) => {
        setSelectedRow(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDate(null);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_HOST_SERVER}salary/delete/${id}`)
            fetchSalaries()
            showSuccessAlert('تم الحذف بنجاح')
        } catch (err) {
            console.error(err)
        }
    }
    const getMonthNameInArabic = () => {
        const now = new Date();
        const arabicMonth = now.toLocaleString('ar', { month: 'long' });
        setMonthName(arabicMonth);
    };

    useEffect(() => {
        const totalBasicSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.basic) || 0), 0);
        const totalAllowancesSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.allowances) || 0), 0);
        const totalBagAllowanceSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.bagAllowance) || 0), 0);
        const totalIncentivesSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.incentives) || 0), 0);
        const totalOvertimeSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.overtime) || 0), 0);
        const totalAdvanceMonthlySum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.advance) || 0), 0);
        const totalOtherDeductionsSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.otherDeductions) || 0), 0);
        const totalInsuranceDeductionSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.insuranceDeduction) || 0), 0);
        const totalPenaltyDeductionSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.penaltyDeduction) || 0), 0);
        const totalAdvanceDeductionSum = filterData.reduce((acc, salary) => acc + (parseFloat(salary.advanceMonthly) || 0), 0);
        const totalNetSalary = filterData.reduce((acc, salary) => acc + (parseFloat(salary.netSalary) || 0), 0);

        setTotalBasic(totalBasicSum);
        setTotalAllowances(totalAllowancesSum);
        setTotalBagAllowance(totalBagAllowanceSum);
        setTotalIncentives(totalIncentivesSum);
        setTotalOvertime(totalOvertimeSum);
        setTotalAdvanceMonthly(totalAdvanceMonthlySum);
        setTotalOtherDeductions(totalOtherDeductionsSum);
        setTotalInsuranceDeduction(totalInsuranceDeductionSum);
        setTotalPenaltyDeduction(totalPenaltyDeductionSum);
        setTotalAdvanceDeduction(totalAdvanceDeductionSum);
        setNetSalary(totalNetSalary);
    }, [filterData]);

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])

    useEffect(() => {
        fetchSalaries();
        getMonthNameInArabic()
    }, []);

    return (
        <div className="mx-auto p-8">
            <h1 className="text-3xl font-bold mb-6 text-center print:hidden">عرض وتحديث بيانات المرتب</h1>

            <div className="flex flex-wrap justify-between items-center mb-6">
                <div>
                    <input
                        type="text"
                        placeholder="بحث بالاسم"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        className="border-gray-300 rounded-md shadow-sm py-2 px-4"
                    />
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="بحث بالادارة"
                        value={searchDepartment}
                        onChange={(e) => setSearchDepartment(e.target.value)}
                        className="border-gray-300 rounded-md shadow-sm py-2 px-4"
                    />
                </div>
            </div>
            <div className='mt-3'>
                <button
                    onClick={handlePrint}
                    className="bg-lime-700 hover:bg-lime-600 text-white py-2 px-4 rounded-lg w-fit"
                >
                    طباعة
                </button>
            </div>
            <div className="text-center mb-6">
                <button
                    onClick={handleFilter}
                    className="bg-lime-500 hover:bg-lime-600 text-white py-2 px-4 rounded-lg w-[50%]"
                >
                    تصفية
                </button>
            </div>

            <div className="overflow-x-auto mt-4">
                <h3 className='text-center text-2xl block print:hidden mb-3'> مرتبات لشهر ({monthName})</h3>
                <h3 className='text-center text-2xl hidden print:block'>صرف مرتبات لشهر ({monthName})</h3>
                <h3 className='text-center text-2xl mt-4 hidden print:block'>ادارة {searchDepartment}</h3>
                <table className="relative min-w-full bg-white shadow-md rounded-lg border">
                    <thead className="border">
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal border">
                            <th colSpan="3" className="border"></th>
                            <th colSpan="6" className="py-3 px-6 text-center text-xl border">استحقاقات</th>
                            <th colSpan="6" className="py-3 px-6 text-center text-xl border">استقطاعات</th>
                            <th colSpan="4" className="py-3 px-6 text-end text-lg print:hidden border">الإجراءات</th>
                        </tr>
                        <tr className="bg-gray-100 text-gray-600 text-sm leading-normal border">
                            <th className="py-3 px-3 text-right text-lg border">الاسم</th>
                            <th className="py-3 px-3 text-right text-lg border">الإدارة</th>
                            <th className="py-3 px-3 text-right text-lg border">الوظيفة</th>
                            <th className="py-3 px-3 text-right border">المرتب الأساسي</th>
                            <th className="py-3 px-3 text-right border">البدلات</th>
                            <th className="py-3 px-3 text-right border">بدل شنطة</th>
                            <th className="py-3 px-3 text-right border">حوافز</th>
                            <th className="py-3 px-3 text-right border">اضافي</th>
                            <th className="py-3 px-3 text-right border">اجمالي الاستحقاقات</th>
                            <th className="py-3 px-3 text-right border">سلف</th>
                            <th className="py-3 px-3 text-right border">خصومات أخرى</th>
                            <th className="py-3 px-3 text-right border">خصم تأمين</th>
                            <th className="py-3 px-3 text-right border">خصم جزاء</th>
                            <th className="py-3 px-3 text-right border">خصم سلف</th>
                            <th className="py-3 px-3 text-right border">اجمالي الخصومات</th>
                            <th className="py-3 px-3 text-right border">صافي المرتب</th>
                            <th colSpan="2" className="border"></th>
                        </tr>
                    </thead>
                    <tbody className="border">
                        {filterData.filter((item) => item.working === false).map((salary, index) => (
                            <tr key={salary._id} className="border-b border">
                                <td className="py-3 px-3 text-right border">{salary.name}</td>
                                <td className="py-3 px-3 text-right border">{salary.departement}</td>
                                <td className="py-3 px-3 text-right border">{salary.jobTitle}</td>
                                {['basic', 'allowances', 'bagAllowance', 'incentives', 'overtime', 'totalSalary'].map((field) => (
                                    <td key={field} className="py-3 px-3 text-right border">
                                        {editIndex === index ? (
                                            <input
                                                type="number"
                                                value={salary[field]}
                                                onChange={(e) => handleFieldChange(e, index, field)}
                                                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            salary[field]
                                                ? salary[field].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                : 0
                                        )}
                                    </td>
                                ))}
                                {['advance', 'otherDeductions', 'insuranceDeduction', 'penaltyDeduction', 'advanceMonthly', 'totalDeductions'].map((field) => (
                                    <td key={field} className="py-3 px-3 text-right border">
                                        {editIndex === index ? (
                                            <input
                                                type="number"
                                                value={salary[field]}
                                                onChange={(e) => handleFieldChange(e, index, field)}
                                                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            salary[field]
                                                ? salary[field].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                : 0
                                        )}
                                    </td>
                                ))}
                                <td className="py-3 px-3 text-right border">
                                    {salary.netSalary.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                                <td className="py-3 px-3 text-right flex flex-col space-y-3 print:hidden border">
                                    {editIndex === index ? (
                                        <>
                                            <button
                                                onClick={() => handleSubmit(salary._id, index)}
                                                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                                            >
                                                حفظ
                                            </button>
                                            <button
                                                onClick={() => setEditIndex(null)}
                                                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                                            >
                                                إلغاء
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => handleDelete(salary._id)}
                                                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                                            >
                                                حذف
                                            </button>
                                            <button
                                                onClick={() => setEditIndex(index)}
                                                className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-lg"
                                            >
                                                تعديل
                                            </button>
                                            <button
                                                onClick={() => openModal(index)}
                                                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg"
                                            >
                                                صرف
                                            </button>
                                        </>
                                    )}
                                </td>
                                <td className="py-3 px-16 text-right hidden print:block border">
                                    <p className="text-lg mb-8 border">توقيع المستلم</p>
                                </td>
                            </tr>
                        ))}
                        <tr className="border-b border">
                            <td colSpan="3" className="text-center text-xl border">الاجماليات</td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalBasic.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalAllowances.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalBagAllowance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalIncentives.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalOvertime.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">
                                    {(totalBasic + totalAllowances + totalBagAllowance + totalIncentives + totalOvertime).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalAdvanceMonthly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalOtherDeductions.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalInsuranceDeduction.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalPenaltyDeduction.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{totalAdvanceDeduction.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">
                                    {(totalOtherDeductions + totalInsuranceDeduction + totalPenaltyDeduction + totalAdvanceDeduction).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </span>
                            </td>
                            <td className="border">
                                <span className="px-3 text-center text-lg">{netSalary.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="grid grid-cols-2 gap-4 p-4">
                    <div className="col-span-2">
                        <h1 className="text-2xl font-bold mt-2 mb-4 text-center">إجمالي المرتبات: {netSalary.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} جنيها</h1>
                    </div>

                </div>

            </div>

            {isModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4">
                        <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-lg">
                            <div className="p-6">
                                <h2 className="text-2xl font-semibold mb-4">تحديد تاريخ الصرف</h2>
                                <DatePicker
                                    selected={date}
                                    onChange={(newDate) => setDate(newDate)}
                                    dateFormat="dd/MM/yyyy"
                                    className="mt-1 block w-full bg-gray-300  rounded-md shadow-sm sm:text-sm"
                                />
                                <div className="mt-6 flex justify-end">
                                    <button
                                        onClick={closeModal}
                                        className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                                    >
                                        إغلاق
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="hidden mt-5 print:flex justify-between">
                <div>
                    <p className="text-xl text-left">توقيع الحسابات</p>
                </div>
                <div>
                    <p className="text-xl text-right">توقيع مدبر الكنيسة</p>
                </div>
            </div>
        </div>
    );
};

export default NonWorkerSalary;
