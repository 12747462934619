import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../../components/Navbar";

const AddDailyList = () => {
    const navigate = useNavigate();
    const { place } = useParams();
    const [listName, setListName] = useState('');
    const [startListMonthes, setStartListMonthes] = useState('');
    const [endListMonthes, setEndListMonthes] = useState('');
    const [listPrice, setListPrice] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        })
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        if (!listName || !startListMonthes || !endListMonthes || !listPrice) {
            setLoading(false);
            return showRequiredAlert('تأكد من ملئ جميع البيانات');
        }

        if (+startListMonthes >= +endListMonthes) {
            setLoading(false);
            return showErrorAlert('يجب أن يكون تاريخ بداية اللائحة أقل من تاريخ نهايتها');
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}dailylist/add`, {
                listName,
                startListMonthes,
                endListMonthes,
                listPrice,
            });

            showSuccessAlert('تمت إضافة اللائحة اليومية بنجاح');
            setSuccess("تمت إضافة اللائحة اليومية بنجاح");
            setLoading(false);
            setError(null);

            setListName('');
            setStartListMonthes('');
            setEndListMonthes('');
            setListPrice('');

        } catch (err) {
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما تأكد من صحة البيانات']);
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما تأكد من صحة البيانات']);
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    return (
        <div>
            <div className="px-4">
                <Navbar />
            </div>
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">اسم اللائحة اليومية</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={listName}
                        placeholder="اسم اللائحة اليومية"
                        onChange={(e) => setListName(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">بداية اللائحة ( بالشهور)</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={startListMonthes}
                        placeholder="لو البداية من 3 شهور ف ندخل 3"
                        onChange={(e) => setStartListMonthes(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">نهاية اللائحة ( بالشهور)</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={endListMonthes}
                        placeholder="لو النهاية سنة ندخل 12"
                        onChange={(e) => setEndListMonthes(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">مبلغ اللائحة</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={listPrice}
                        placeholder="ادخل مبلغ اللائحة"
                        onChange={(e) => setListPrice(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                    >
                        أضافة اللائحة اليومية
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddDailyList;
