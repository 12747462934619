import axios from "axios";
import { useEffect } from "react";
import { Link, useNavigate, useParams} from "react-router-dom";
import Navbar from "../../../components/Navbar";

const Nursury = () => {
    const navigate = useNavigate();
    const { place } = useParams();

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    return (
        <div>
            <Navbar />
            <h1 className='text-center text-3xl'>اهلا مسئول الحضانة</h1>
            <div className="min-h-[60vh] flex items-center justify-center">
                <div className="flex flex-wrap justify-center gap-4">
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة طفل</h2>
                        <Link to={'add/child'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">الاطفال</h2>
                        <Link to={'children'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة لائحة شهرية</h2>
                        <Link to={'add/list'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center"> اللوائح الشهرية</h2>
                        <Link to={'lists'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة لائحة يومية</h2>
                        <Link to={'add/dailylist'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center"> اللوائح اليومية</h2>
                        <Link to={'dailylists'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة مصاريف</h2>
                        <Link to={'add/expenses'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة ايرادات</h2>
                        <Link to={'add/incomes'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">حركة المصروفات و الايرادات</h2>
                        <Link to={'nursery/finance'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nursury;
