import Swal from "sweetalert2";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar";

const DailyList = () => {
    const navigate = useNavigate();
    const { place } = useParams();
    const [dailyLists, setDailyLists] = useState([]);
    const [editingList, setEditingList] = useState(null);
    const [formData, setFormData] = useState({
        listName: '',
        startListMonthes: '',
        endListMonthes: '',
        listPrice: ''
    });

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const fetchDailyLists = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}dailylist/`);
            setDailyLists(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleEdit = (list) => {
        setEditingList(list._id);
        setFormData({
            listName: list.listName,
            startListMonthes: list.startListMonthes,
            endListMonthes: list.endListMonthes,
            listPrice: list.listPrice
        });
    };

    const handleSave = async () => {
        if (formData.startListMonthes >= formData.endListMonthes) {
            showErrorAlert('تاريخ البداية يجب أن يكون قبل تاريخ النهاية');
            return;
        }
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}dailylist/${editingList}`, formData);
            showSuccessAlert('تم التحديث بنجاح');
            fetchDailyLists();
            setEditingList(null);
        } catch (err) {
            showErrorAlert('حدث خطأ ما');
            console.error(err);
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}dailylist/${id}`);
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchDailyLists();
                } catch (err) {
                    showErrorAlert('حدث خطأ ما');
                }
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        verifyAdmin();
        fetchDailyLists();
    }, []);

    return (
        <div className="container mx-auto px-6 py-8">
            <Navbar/>
            <h1 className="text-3xl font-semibold mb-6 text-right">قائمة اللوائح اليومية</h1>
            <table className="w-full border border-gray-300 rounded-lg overflow-hidden shadow-lg text-center">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="border border-gray-300 p-4">اسم اللائحة</th>
                        <th className="border border-gray-300 p-4">بداية اللائحة ( بالشهور)</th>
                        <th className="border border-gray-300 p-4">نهاية اللائحة ( بالشهور)</th>
                        <th className="border border-gray-300 p-4">السعر</th>
                        <th className="border border-gray-300 p-4">الإجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {dailyLists.map((list) => (
                        <tr key={list._id} className="border-b border-gray-300 hover:bg-gray-100 transition-colors">
                            <td className="p-4">
                                {editingList === list._id ? (
                                    <input
                                        type="text"
                                        name="listName"
                                        value={formData.listName}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                        placeholder="اسم اللائحة"
                                    />
                                ) : (
                                    list.listName
                                )}
                            </td>
                            <td className="p-4">
                                {editingList === list._id ? (
                                    <input
                                        type="number"
                                        name="startListMonthes"
                                        value={formData.startListMonthes}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                        placeholder="بداية اللائحة"
                                    />
                                ) : (
                                    list.startListMonthes
                                )}
                            </td>
                            <td className="p-4">
                                {editingList === list._id ? (
                                    <input
                                        type="number"
                                        name="endListMonthes"
                                        value={formData.endListMonthes}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                        placeholder="نهاية اللائحة"
                                    />
                                ) : (
                                    list.endListMonthes
                                )}
                            </td>
                            <td className="p-4">
                                {editingList === list._id ? (
                                    <input
                                        type="number"
                                        name="listPrice"
                                        value={formData.listPrice}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                        placeholder="السعر"
                                    />
                                ) : (
                                    list.listPrice
                                )}
                            </td>
                            <td className="p-4 text-center">
                                {editingList === list._id ? (
                                    <button
                                        onClick={handleSave}
                                        className="bg-blue-600 text-white px-8 mx-4 py-2 rounded-lg transition duration-200 hover:bg-blue-500"
                                    >
                                        حفظ
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleEdit(list)}
                                        className="bg-yellow-500 text-white px-8 mx-4 py-2 rounded-lg transition duration-200 hover:bg-yellow-400"
                                    >
                                        تعديل
                                    </button>
                                )}
                                <button
                                    onClick={() => handleDelete(list._id)}
                                    className="bg-red-600 text-white px-8 mx-4 py-2 rounded-lg transition duration-200 hover:bg-red-500 ml-2"
                                >
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DailyList;
