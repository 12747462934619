import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const SalesPlaces = () => {
    const [salesData, setSalesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [salesPlace, setSalesPlace] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [places, setPlaces] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate('');

    const fetchSalesData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/sales`);
            setSalesData(response.data.data);
            setFilteredData(response.data.data);
            const uniquePlaces = [...new Set(response.data.data.map((sale) => sale.Sales_Place))];
            setPlaces(uniquePlaces);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFilter = () => {
        let data = salesData;
        if (salesPlace) {
            data = data.filter((sale) => sale.Sales_Place === salesPlace);
        }
        if (startDate && endDate) {
            data = data.filter((sale) => {
                const saleDate = new Date(sale.Sales_Time);
                return saleDate >= new Date(startDate) && saleDate <= new Date(endDate);
            });
        }
        setFilteredData(data);
        const totalPrice = data.reduce((acc, sale) => acc + (sale.Sales_Price || 0), 0);
        setTotalPrice(totalPrice);
        setCurrentPage(1);
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, []);

    useEffect(() => {
        fetchSalesData();
    }, []);

    useEffect(() => {
        handleFilter();
    }, [salesPlace, startDate, endDate]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <Navbar />
            <div className="container mx-auto bg-white rounded-xl shadow-lg p-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">بيانات المبيعات</h1>
                <div className="flex flex-col md:flex-row gap-4 mb-6">
                    <select
                        value={salesPlace}
                        onChange={(e) => setSalesPlace(e.target.value)}
                        className="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">تصفية حسب المكان</option>
                        {places.map((place, index) => (
                            <option key={index} value={place}>
                                {place}
                            </option>
                        ))}
                    </select>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 px-4 py-2 text-right">كود البيع</th>
                            <th className="border border-gray-300 px-4 py-2 text-right">المنتج</th>
                            <th className="border border-gray-300 px-4 py-2 text-right">الكمية</th>
                            <th className="border border-gray-300 px-4 py-2 text-right">السعر</th>
                            <th className="border border-gray-300 px-4 py-2 text-right">المكان</th>
                            <th className="border border-gray-300 px-4 py-2 text-right">التاريخ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((sale, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_Code}</td>
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_product}</td>
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_Quantity ? sale.Sales_Quantity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "تم الارجاع"}</td>
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_Price ? sale.Sales_Price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "تم الارجاع"}</td>
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_Place}</td>
                                <td className="border border-gray-300 px-4 py-2 text-right">{sale.Sales_Time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="my-4 text-right">
                    <p className='text-center text-xl'>إجمالي : {totalPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                <div className="flex justify-between items-center mt-6">
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-400"
                        disabled={currentPage === 1}
                    >
                        السابق
                    </button>
                    <div className="flex items-center">
                        <input
                            type="number"
                            min="1"
                            max={Math.ceil(filteredData.length / itemsPerPage)}
                            value={currentPage}
                            onChange={(e) => paginate(Number(e.target.value))}
                            className="w-20 px-2 py-1 border border-gray-300 rounded-lg text-center"
                        />
                    </div>
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-400"
                        disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                    >
                        التالي
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SalesPlaces;
