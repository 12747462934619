import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UpdatePlace = () => {
    const [places, setPlaces] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [newplace, setNewplace] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const placesPerPage = 20;

    const fetchPlaces = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`);
            setPlaces(response.data.data);
        } catch (error) {
            console.error('Error fetching places:', error);
        }
    };

    const handleEditClick = (place) => {
        setSelectedPlace(place);
        setNewplace(place.place);
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/place`, {
                _id: selectedPlace._id,
                place: newplace
            });
            setSelectedPlace(null);
            fetchPlaces();
        } catch (error) {
            console.error('Error updating place:', error);
        }
    };

    const filteredPlaces = places.filter((place) =>
        place.place.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastPlace = currentPage * placesPerPage;
    const indexOfFirstPlace = indexOfLastPlace - placesPerPage;
    const currentPlaces = filteredPlaces.slice(indexOfFirstPlace, indexOfLastPlace);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])
    useEffect(() => {
        fetchPlaces();
    }, []);

    return (
        <div className="p-6">
            <h1 className="text-3xl text-yellow-500 mb-6">إدارة الأماكن</h1>

            <input
                type="text"
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                placeholder="ابحث عن المكان"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <table className="w-full text-right">
                <thead>
                    <tr>
                        <th className="text-yellow-500 p-2">اسم المكان</th>
                        <th className="text-yellow-500 p-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPlaces.map((place) => (
                        <tr key={place._id}>
                            <td className="p-2">{place.place}</td>
                            <td className="p-2">
                                <button
                                    onClick={() => handleEditClick(place)}
                                    className="text-yellow-500 underline"
                                >
                                    تعديل
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mt-4">
                <ul className="flex justify-center">
                    {Array.from({ length: Math.ceil(filteredPlaces.length / placesPerPage) }, (_, index) => (
                        <li key={index} className="mx-1">
                            <button
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 border ${currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'border-yellow-500 text-yellow-500'}`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {selectedPlace && (
                <div className="mt-6">
                    <h2 className="text-xl text-yellow-500 mb-4">تعديل المكان</h2>

                    <div className="mb-4">
                        <label className="text-yellow-500">اسم المكان:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newplace}
                            onChange={(e) => setNewplace(e.target.value)}
                        />
                    </div>

                    <button
                        onClick={handleSave}
                        className="text-yellow-500 underline mt-4"
                    >
                        حفظ التعديلات
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpdatePlace;
