import { useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Login = () => {
    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error,setError] = useState('')
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/login`, {
            adminEmail:email.toLocaleLowerCase(), adminPassword:password
        }).then((res) => {
            console.log(res.data.place)
            sessionStorage.setItem('token', res.data.refreshToken)
            if (res.data.role === 'عامل') {
                navigate('/salesandreturns/' + res.data.place)
            }
            else if (res.data.role === 'مسئول') {
                navigate('/admin/finance')
            }
            else if (res.data.place === 'اخوة رب') {
                navigate('/admin/god')
            }
            else if (res.data.place === 'حضانة') {
                navigate('/nursery/' + res.data.place)
            }
        }).catch((err) => {
            setError(err.response.data.error)
            console.log(err.response.data.error)
        })
    }
    return (
        <div className="login-page w-[90%] m-auto">
            <Navbar />
            <div className="login-heading mt-5">
                <h1 className="text-center text-3xl font-bold">قم بتسحيل الدخول الأن !</h1>
            </div>  
            <div className="form w-[90%] lg:w-[50%] m-auto">
                <form>
                    <div className="form-group mt-4">
                        <label className="text-xl">البريد الاكتروني</label>
                        <input type="text" className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">كلمة المرور</label>
                        <input type="text" className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2" onChange={(e) => {
                            setPassword(e.target.value)
                        }}/>
                    </div>
                    <div className="submit mt-6 text-center">
                        <button className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200" onClick={handleSubmit}>تسجيل الدخول</button>
                    </div>
                    <div className="Error-section mt-6 text-center">
                        <p className="text-lg text-red-600">{error}</p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;