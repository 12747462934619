import Swal from "sweetalert2";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UpdateOccasion = () => {
    const [occasions, setOccasions] = useState([]);
    const [selectedOccasion, setSelectedOccasion] = useState(null);
    const [newOccasion, setNewOccasion] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const occasionsPerPage = 20;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate('');

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        })
    };


    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };


    const fetchOccasions = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}occasion/`);
            setOccasions(response.data);
        } catch (error) {
            console.error('Error fetching occasions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (occasion) => {
        setSelectedOccasion(occasion);
        setNewOccasion(occasion.occasion);
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}occasion/${selectedOccasion._id}`, {
                _id: selectedOccasion._id,
                occasion: newOccasion
            });
            setSelectedOccasion(null);
            showSuccessAlert("تم تحديث البيانات بنجاح")
            fetchOccasions();
        } catch (error) {
            showErrorAlert('حدث خطأ ما اثناء التحديث')
            console.error('Error updating occasion:', error);
        }
    };

    const handleDelete = async (occasionId) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}occasion/${occasionId}`);
                    fetchOccasions();
                    showSuccessAlert("تم حذف البيانات بنجاح");
                } catch (error) {
                    showErrorAlert('حدث خطأ ما اثناء الحذف');
                }
            }
        });
    };


    const filteredOccasions = occasions.filter((occasion) =>
        occasion.occasion.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastOccasion = currentPage * occasionsPerPage;
    const indexOfFirstOccasion = indexOfLastOccasion - occasionsPerPage;
    const currentOccasions = filteredOccasions.slice(indexOfFirstOccasion, indexOfLastOccasion);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        fetchOccasions();
    }, []);

    return (
        <div className="p-6">
            {loading && <div className="text-yellow-500">Loading...</div>}
            <h1 className="text-3xl text-yellow-500 mb-6">إدارة المناسبات</h1>
            <input
                type="text"
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                placeholder="ابحث عن المناسبة"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <table className="w-full text-right">
                <thead>
                    <tr>
                        <th className="text-yellow-500 p-2">اسم المناسبة</th>
                        <th className="text-yellow-500 p-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentOccasions.map((occasion) => (
                        <tr key={occasion._id}>
                            <td className="p-2">{occasion.occasion}</td>
                            <td className="p-2">
                                <button
                                    onClick={() => handleEditClick(occasion)}
                                    className="text-yellow-500 underline"
                                >
                                    تعديل
                                </button>
                                <button
                                    onClick={() => handleDelete(occasion._id)}
                                    className="text-red-500 underline ml-4 mx-4"
                                >
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="mt-4">
                <ul className="flex justify-center">
                    {Array.from({ length: Math.ceil(filteredOccasions.length / occasionsPerPage) }, (_, index) => (
                        <li key={index} className="mx-1">
                            <button
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 border ${currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'border-yellow-500 text-yellow-500'}`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {selectedOccasion && (
                <div className="mt-6">
                    <h2 className="text-xl text-yellow-500 mb-4">تعديل المناسبة</h2>
                    <div className="mb-4">
                        <label className="text-yellow-500">اسم المناسبة:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newOccasion}
                            onChange={(e) => setNewOccasion(e.target.value)}
                        />
                    </div>
                    <button
                        onClick={handleSave}
                        className="text-yellow-500 underline mt-4"
                    >
                        حفظ التعديلات
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpdateOccasion;
