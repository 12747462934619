import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar";

const ChildrenData = () => {
    const navigate = useNavigate();
    const { place } = useParams();
    const [filteredChildren, setFilteredChildren] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [children, setChildren] = useState([]);
    const [editingChild, setEditingChild] = useState(null);
    const [formData, setFormData] = useState({
        childName: "",
        childBirthDate: "",
        childAge: "",
        childMotherPhone: "",
        childAddress: ""
    });

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchChildren = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}nursery/`);
            setChildren(res.data);
        } catch (error) {
            console.error(error);
        }
    };
    

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };
    

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}nursery/delete/${id}`);
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchChildren();
                } catch (err) {
                    showErrorAlert('حدث خطأ ما');
                }
            }
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleEdit = (child) => {
        setEditingChild(child._id);
        setFormData({
            childName: child.childName,
            childBirthDate: child.childBirthDate.split("T")[0],
            childAge: `${calculateAge(child.childBirthDate)}`,
            childMotherPhone: child.childMotherPhone,
            childAddress: child.childAddress
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            childAge: name === "childBirthDate" ? `${calculateAge(value)}` : prevFormData.childAge
        }));
    };

    const calculateAge = (birthDate) => {
        const birth = new Date(birthDate);
        const today = new Date();
        const yearsDiff = today.getFullYear() - birth.getFullYear();
        const monthsDiff = today.getMonth() - birth.getMonth();
        const totalMonths = yearsDiff * 12 + monthsDiff;
        return totalMonths;
    };

    const handleSave = async () => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_HOST_SERVER}nursery/update/${editingChild}`, formData);
            const updatedChild = res.data;
            setChildren((prevChildren) => prevChildren.map(child =>
                child._id === editingChild ? updatedChild : child
            ));
            setFilteredChildren((prevFiltered) => prevFiltered.map(child =>
                child._id === editingChild ? updatedChild : child
            ));
            setEditingChild(null);
            showSuccessAlert("تم التحديث بنجاح");
        } catch (error) {
            showErrorAlert('حدث خطأ أثناء تحديث البيانات');
            console.error(error);
        }
    };
    

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        if(children){
            const filteredData = children.filter((child) =>
                child.childName.toLowerCase().includes(searchTerm)
            );
            setFilteredChildren(filteredData);
        }
    }, [searchTerm, children]);

    useEffect(() => {
        fetchChildren();
    }, []);

    return (
        <div className="container mx-auto px-6 py-8">
            <Navbar />
            <h1 className="text-3xl font-semibold mb-6 text-right">قائمة الأطفال</h1>

            <input
                type="text"
                placeholder="ابحث باسم الطفل"
                className="border rounded-lg px-2 py-1 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-300"
                value={searchTerm}
                onChange={handleSearch}
            />

            <table className="w-full border border-gray-300 rounded-lg overflow-hidden shadow-lg text-center">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="border border-gray-300 p-4">الاسم</th>
                        <th className="border border-gray-300 p-4">تاريخ الميلاد</th>
                        <th className="border border-gray-300 p-4">العمر بالاشهر</th>
                        <th className="border border-gray-300 p-4">رقم هاتف الأم</th>
                        <th className="border border-gray-300 p-4">العنوان</th>
                        <th className="border border-gray-300 p-4">الإجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredChildren.map((child) => (
                        <tr key={child._id} className="border-b border-gray-300 hover:bg-gray-100 transition-colors">
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <input
                                        type="text"
                                        name="childName"
                                        value={formData.childName}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    />
                                ) : (
                                    child.childName
                                )}
                            </td>
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <input
                                        type="date"
                                        name="childBirthDate"
                                        value={formData.childBirthDate}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    />
                                ) : (
                                    new Date(child.childBirthDate).toLocaleDateString('en-uk')
                                )}
                            </td>
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <input
                                        type="text"
                                        name="childAge"
                                        readOnly
                                        value={formData.childAge}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    />
                                ) : (
                                    `${calculateAge(child.childBirthDate)}`
                                )}
                            </td>
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <input
                                        type="text"
                                        name="childMotherPhone"
                                        value={formData.childMotherPhone}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    />
                                ) : (
                                    child.childMotherPhone
                                )}
                            </td>
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <input
                                        type="text"
                                        name="childAddress"
                                        value={formData.childAddress}
                                        onChange={handleChange}
                                        className="border rounded-lg px-2 py-1 w-full focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    />
                                ) : (
                                    child.childAddress
                                )}
                            </td>
                            <td className="p-4">
                                {editingChild === child._id ? (
                                    <button
                                        onClick={handleSave}
                                        className="bg-blue-600 text-white px-8 mx-4 my-4 py-2 rounded-lg hover:bg-blue-500"
                                    >
                                        حفظ
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleEdit(child)}
                                        className="bg-yellow-500 text-white px-8 mx-4 py-2 rounded-lg hover:bg-yellow-400"
                                    >
                                        تعديل
                                    </button>
                                )}
                                <button
                                    onClick={() => handleDelete(child._id)}
                                    className="bg-red-500 text-white px-8 py-2 rounded-lg hover:bg-red-400"
                                >
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChildrenData;
