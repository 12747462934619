import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminGod = () => {
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'اخوة رب') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    },[])
    return (
        <div>
            <Navbar />
            <h1 className='text-center text-3xl'>اهلا مسئول اخوة الرب</h1>
        <div className="min-h-[60vh] flex  items-center justify-center ">
            <div className="flex flex-wrap justify-center gap-4">
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">اضافة اسرة</h2>
                        <Link to={'/god/add'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                </div>
                <div className="bg-white rounded-xl shadow-2xl transform hover:-translate-y-2 hover:shadow-xl transition duration-500 ease-in-out p-6 w-80">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">بحث عن الاسرة</h2>
                        <Link to={'/god/search'}>
                            <button className="w-full py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg font-semibold transition duration-300">انتقل</button>
                        </Link>
                </div>
            </div>
        </div>
    </div>
    );
};

export default AdminGod;
