import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddBlessing = () => {
    const [blessings, setBlessings] = useState([]);
    const [servedblessings, setServedBlessings] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [occasion, setOccasion] = useState("");
    const [occasion2, setOccasion2] = useState("");
    const [filteredBlessings, setFilteredBlessings] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedBlessing, setSelectedBlessing] = useState(null);
    const [servedDate, setServedDate] = useState("");
    const [eligibilityFilter, setEligibilityFilter] = useState("");
    const [occasionFilter, setOccasionFilter] = useState("");

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchBlessings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}salary`);
            setBlessings(response.data.data);
            setFilteredBlessings(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchWorkers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers`);
            setWorkers(response.data.workers);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchOccasions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}occasion/`);
            setOccasions(response.data);
        } catch (error) {
            console.error("Error fetching occasions:", error);
        }
    };

    const fetchServedBlessings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}blessing/`);
            setServedBlessings(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const uniqueOccasions = [...new Set(servedblessings.map((blessing) => blessing.occasion.occasion))];

    useEffect(() => {
        fetchBlessings();
        fetchWorkers();
        fetchOccasions();
        fetchServedBlessings();
    }, []);

    const handleSearch = () => {
        let filtered = blessings;

        if (nameFilter) {
            filtered = filtered.filter((blessing) =>
                blessing.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (departmentFilter) {
            filtered = filtered.filter((blessing) =>
                blessing.departement.toLowerCase().includes(departmentFilter.toLowerCase())
            );
        }

        if (eligibilityFilter) {
            filtered = filtered.filter((blessing) => {
                const worker = workers.find((worker) => worker.name === blessing.name);
                const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
                const isEligibleForBlessing = worker && new Date() - new Date(worker.startDate) > oneYearInMillis;
                return eligibilityFilter === "يستحق البركة" ? isEligibleForBlessing : !isEligibleForBlessing;
            });
        }

        if (occasionFilter) {
            filtered = filtered.filter((blessing) => {
                const isMatchingOccasion = servedblessings.some(
                    (servedBlessing) =>
                        servedBlessing.name === blessing.name &&
                        servedBlessing.occasion.occasion === occasionFilter
                );
                return !isMatchingOccasion;
            });
        }


        setFilteredBlessings(filtered);
    };


    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    const uniqueDepartments = [...new Set(blessings.map((blessing) => blessing.departement))];

    useEffect(() => {
        verifyAdmin();
    }, []);

    const handleServe = async (blessing) => {
        setSelectedBlessing(blessing);
        setShowModal(true);
    };

    const handleSubmitDate = async () => {
        if (!servedDate) {
            showRequiredAlert("يرجى إدخال التاريخ");
            return;
        }
        if (!occasion) {
            showRequiredAlert("يرجى إدخال المناسبة او العيد");
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}blessing/blessings`, {
                name: selectedBlessing.name,
                departement: selectedBlessing.departement,
                jobTitle: selectedBlessing.jobTitle,
                basic: selectedBlessing.basic,
                servedDate: servedDate || new Date().toISOString().split("T")[0],
                startDate: workers.find(worker => worker.name === selectedBlessing.name)?.startDate,
                occasion,
            });
            showSuccessAlert("تم صرف البركة بنجاح");
            setShowModal(false);
            setServedDate("");
            fetchServedBlessings();
        } catch (error) {
            showErrorAlert(error.response.data.message ? error.response.data.message : "حدث خطأ أثناء صرف البركة");
            console.log('err', error)
        }
    };

    const handleOccasionName = (item) => {
        setOccasion(item._id)
        setOccasion2(item.occasion)
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center print:hidden">إضافة بركة</h1>
            <h1 className="text-3xl font-bold mb-8 text-center hidden print:block">صرف بركة {occasion2}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <input
                    type="text"
                    placeholder="البحث بالاسم"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <select
                    value={departmentFilter}
                    onChange={(e) => setDepartmentFilter(e.target.value)}
                    className="border-gray-300 rounded-md shadow-sm py-2 px-4"
                >
                    <option selected value="">الادارات</option>
                    {uniqueDepartments.map((department) => (
                        <option key={department} value={department}>
                            {department}
                        </option>
                    ))}
                </select>

                <select
                    onChange={(e) => setEligibilityFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right print:hidden"
                >
                    <option value="">بحث حسب الاستحقاق</option>
                    <option value="يستحق البركة">يستحق البركة</option>
                    <option value="لا يستحق">لا يستحق</option>
                </select>

                <select
                    value={occasionFilter}
                    onChange={(e) => setOccasionFilter(e.target.value)}
                    className="border-gray-300 rounded-md shadow-sm py-2 px-4 print:hidden"
                >
                    <option selected value="">الاعياد المصروفة</option>
                    {uniqueOccasions.map((occasion) => (
                        <option key={occasion} value={occasion}>
                            {occasion}
                        </option>
                    ))}
                </select>

            </div>

            <button
                onClick={handleSearch}
                className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
            >
                بحث
            </button>

            <button
                onClick={() => {
                    window.print()
                }}
                className="mb-6 mx-5 px-6 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-lg hover:bg-green-700"
            >
                طباعة
            </button>

            <div className="occasion-for-blessing">
                <select
                    onChange={(e) => {
                        const selectedOccasion = occasions.find((occasion) => occasion._id === e.target.value);
                        handleOccasionName(selectedOccasion); 
                    }}
                    className="p-3 border w-[50%] mb-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                >
                    <option value="" selected>أختر المناسبة او العيد</option>
                    {occasions && occasions.map((item) => (
                        <option key={item._id} value={item._id}>
                            {item.occasion}
                        </option>
                    ))}
                </select>
            </div>


            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-lg rounded-lg">
                    <thead className="bg-indigo-700 text-white">
                        <tr>
                            <th className="py-4 px-6 text-right border">الاسم</th>
                            <th className="py-4 px-6 text-right border">الادارة</th>
                            <th className="py-4 px-6 text-right border">الوظيفة</th>
                            <th className="py-4 px-6 text-right border">المرتب الأساسي</th>
                            <th className="py-4 px-6 text-right border">تاريخ التعيين</th>
                            <th className="py-4 px-6 text-right border">هل يستحق البركة</th>
                            <th className="py-4 px-6 text-center print:hidden border">إجراءات</th>
                            <th className="py-9 px-6 text-center hidden print:block border">الامضائات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBlessings
                            .filter((blessing) => {
                                return !servedblessings.some(
                                    (servedBlessing) =>
                                        servedBlessing.name === blessing.name &&
                                        servedBlessing.occasion.occasion === occasionFilter
                                );
                            })
                            .map((blessing, index) => {
                                const worker = workers.find((worker) => worker.name === blessing.name);
                                const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
                                const isEligibleForBlessing = worker && new Date() - new Date(worker.startDate) > oneYearInMillis;

                                return (
                                    <tr key={index}>
                                        <td className="py-4 px-6 text-right border">{blessing.name}</td>
                                        <td className="py-4 px-6 text-right border">{blessing.departement}</td>
                                        <td className="py-4 px-6 text-right border">{blessing.jobTitle}</td>
                                        <td className="py-4 px-6 text-right border">
                                            {blessing.basic.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td className="py-4 px-6 text-right border">
                                            {worker ? new Date(worker.startDate).toLocaleDateString("en-uk") : "N/A"}
                                        </td>
                                        <td className="py-4 px-6 text-right border">{isEligibleForBlessing ? "يستحق البركة" : "لا يستحق"}</td>
                                        <td className="py-4 px-6 text-center border print:hidden">
                                            {isEligibleForBlessing ? (
                                                <button
                                                    onClick={() => handleServe(blessing)}
                                                    className="px-4 py-2 w-[120px] text-center bg-lime-600 text-white rounded-lg hover:bg-lime-700"
                                                >
                                                    صرف
                                                </button>
                                            ) : (
                                                <button className="px-4 py-2 w-[120px] text-center bg-red-600 text-white rounded-lg hover:bg-red-700">
                                                    لا يمكن الصرف
                                                </button>
                                            )}
                                        </td>
                                        <td className="py-3 px-16 text-right hidden print:block border">
                                            <p className="text-lg w-40 text-center mb-8">توقيع المستلم</p>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>


                </table>
            </div>

            {showModal && (
                <div className="fixed  inset-0 flex items-center justify-center z-50">
                    <div className="bg-white w-[400px] rounded-lg shadow-lg p-6">
                        <h2 className="text-lg font-bold mb-4">إدخال تاريخ الصرف</h2>
                        <input
                            type="date"
                            value={servedDate}
                            onChange={(e) => setServedDate(e.target.value)}
                            className="p-3 border w-[50%] mb-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        />
                        <div className="mt-4 flex justify-around">
                            <div>
                                <button
                                    onClick={handleSubmitDate}
                                    className="px-4 py-2 w-[140px] bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                                >
                                    حفظ
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 w-[140px] bg-red-600 text-white rounded-lg hover:bg-red-700 ml-2"
                                >
                                    إلغاء
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-6 text-center">
                <p className="text-lg font-bold">
                    إجمالي البركات: {filteredBlessings.reduce((acc, blessing) => {
                        const worker = workers.find((worker) => worker.name === blessing.name);
                        const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
                        const isEligibleForBlessing =
                            worker &&
                            new Date() - new Date(worker.startDate) > oneYearInMillis;
                        return acc + (isEligibleForBlessing ? blessing.basic : 0);
                    }, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} جنيه
                </p>
            </div>

            <div className="hidden mt-5 print:flex justify-between">
                <div>
                    <p className="text-xl text-left">توقيع الحسابات</p>
                </div>
                <div>
                    <p className="text-xl text-right">توقيع مدبر الكنيسة</p>
                </div>
            </div>

        </div>
    );
};

export default AddBlessing;
