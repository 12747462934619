import React from 'react';
import { Link } from 'react-router-dom'; 

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-9xl font-bold text-yellow-500">404</h1>
                <p className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">
                    عذرًا، الصفحة غير موجودة
                </p>
                <p className="text-md text-gray-600 mb-8">
                    يبدو أنك قد أدخلت رابطًا غير صحيح أو أن الصفحة التي تبحث عنها قد تم نقلها.
                </p>
                <Link to="/" className="px-6 py-2 text-lg text-white bg-yellow-500 rounded-lg hover:bg-yellow-600">
                    العودة إلى الصفحة الرئيسية
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
